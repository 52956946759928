import { Inject, Injectable } from '@angular/core';
import { Store } from '../store';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OverlayService {
    body;
    isBrowser = false;

    public removeSpinnerTimeout;
    public pageLoaded$ = new BehaviorSubject<boolean>(false);

    private loadingSubjects: BehaviorSubject<boolean>[] = [];
    private combinedLoadingSubjects$: Observable<boolean[]>;
    private combinedLoadingSubjectSub: Subscription;
    private removeSpinnerByTimeout$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private store: Store,
        @Inject(PLATFORM_ID) platformId: {},
    ) {
        if (isPlatformBrowser(platformId)) {
            this.isBrowser = true;
            this.body = document.getElementsByTagName('body')[0];
        }
    }

    get() {
        return this.store.selectForLocal('overlays');
    }

    toggleModal(overlayToToggle) {
        const overlays = this.get();

        if (overlays[overlayToToggle]) {
            overlays[overlayToToggle] = false;
        } else {
            overlays[overlayToToggle] = true;
            this.body.classList.add('body-freeze');
        }

        const objKeys = Object.keys(overlays);
        let anOverlayIsOpen = false;
        for (let i = 0; i < objKeys.length; i++) {
            if (overlays[objKeys[i]]) {
                anOverlayIsOpen = true;
            }
        }
        if (anOverlayIsOpen) {
            this.body.classList.add('body-freeze');
        } else {
            this.body.classList.remove('body-freeze');
        }

        this.store.set('overlays', overlays);
    }

    toggle(overlayToToggle) {

        const overlays = this.get();
        const objKeys = Object.keys(overlays);

        for (let i = 0; i < objKeys.length; i++) {
            if (objKeys[i] !== overlayToToggle) {
                overlays[objKeys[i]] = false;
            }
        }

        if (overlays[overlayToToggle]) {
            overlays[overlayToToggle] = false;
            this.body.classList.remove('body-freeze');
            this.store.set('asideOpen', false);
        } else {
            overlays[overlayToToggle] = true;
            this.body.classList.add('body-freeze');
            this.store.set('asideOpen', true);
        }
        this.store.set('overlays', overlays);
    }

    closeAll() {
        const overlays = this.get();
        const objKeys = Object.keys(overlays);

        for (let i = 0; i < objKeys.length; i++) {
            overlays[objKeys[i]] = false;
        }
        if (this.isBrowser) {
            this.body.classList.remove('body-freeze');
        }

        this.store.set('asideOpen', false);
        this.store.set('overlays', overlays);
    }

    toggleChat() {
        const deviceType = this.store.selectForLocal('deviceType');
        const overlays = this.get();
        const objKeys = Object.keys(overlays);
        for (let i = 0; i < objKeys.length; i++) {
            if (objKeys[i] !== 'chat') {
                overlays[objKeys[i]] = false;
            }
        }
        overlays.chat = !overlays.chat;
        if (this.isBrowser) {

            if (deviceType === 'mobile') {
                if (overlays.chat) {
                    this.body.classList.add('body-freeze');
                } else {
                    this.body.classList.remove('body-freeze');
                }
            }
        }
        this.store.set('overlays', overlays);
    }


    initSpinner() {
        this.removeSpinnerTimeout = setTimeout(() => { 
            this.removeSpinnerByTimeout$.next(true);
        }, 3000);
        this.addLoadingSubject(this.removeSpinnerByTimeout$);
        

        return this.pageLoaded$;
    }

    addLoadingSubject(loadingSubject$: BehaviorSubject<boolean>) {
        this.loadingSubjects = this.loadingSubjects.filter(s => s !== this.removeSpinnerByTimeout$);

        this.loadingSubjects.push(loadingSubject$);

        this.combinedLoadingSubjects$ = combineLatest(this.loadingSubjects);

        if(this.combinedLoadingSubjectSub) {
            this.combinedLoadingSubjectSub.unsubscribe();
        }
        
        this.combinedLoadingSubjectSub = this.combinedLoadingSubjects$.pipe(map((res: boolean[]) => {
            return res.every(v => v === true);
        })).subscribe((res: boolean) => {
            return this.pageLoaded$.next(res);
        });
    }
}
