import {Inject, Injectable, isDevMode, Optional, PLATFORM_ID, Renderer2, RendererFactory2} from '@angular/core';
import {GOOGLE_ANALYTICS_SETTINGS_TOKEN} from '../token/google-analytics-settings-token';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {GTAG_FN} from '../token/gtag-token';
import {GaActionEnum} from '../enums/ga-action.enum';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {

    private readonly doc: Document;
    private renderer: Renderer2;
    private prevPage = null;

    constructor(
        @Inject(GOOGLE_ANALYTICS_SETTINGS_TOKEN) private readonly settings,
        @Inject(DOCUMENT) private readonly documentReference: any,
        @Inject(PLATFORM_ID) private platformId: {},
        @Inject(GTAG_FN) private readonly gtagFn,
        @Optional() private router: Router,
        private rendererFactory: RendererFactory2
    ) {
        // DOCUMENT cannot be injected directly as Document type, see https://github.com/angular/angular/issues/20351
        // It is therefore injected as any and then cast to Document
        this.doc = documentReference as Document;
        this.renderer = rendererFactory.createRenderer(null, null);


        /*
        if (router) {
            // Log page views after router navigation ends
            router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((navigationEvents: NavigationEnd) => {

                if (this.isLoaded() && this.prevPage && (this.prevPage !== navigationEvents.url)) {
                    this.pageView(router.url);
                }

                this.prevPage = navigationEvents.url;
            });
        }

         */
    }

    initialize(trackingCode = this.settings.trackingCode): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        if (this.isLoaded()) {
            return;
        }

        this.addGaScript(trackingCode);
    }

    addGaScript(trackingCode) {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        const uri = `https://www.googletagmanager.com/gtag/js?id=${trackingCode}`;

        // Add here new gtag commands for configuring the Google Analytics Tracking code
        const initialCommands = [
            {command: 'consent', values: ['default', {ad_storage: 'granted', analytics_storage: 'granted'}]},
            {command: 'js', values: [new Date()]},
            {command: 'config', values: [trackingCode]},
            {command: 'config', values: [this.settings.adsCode]},
            {command: 'config', values: [this.settings.phoneTrackingId, {phone_conversion_number: this.settings.phoneTrackingNumber}]},
        ];

        if (!trackingCode) {
            if (isDevMode()) {
                console.error('Empty tracking code for Google Analytics. Make sure to provide one in the environment.');
            }

            return;
        }

        if (!this.gtagFn) {
            if (isDevMode()) {
                console.error('Was not possible create or read gtag.');
            }

            return;
        }

        if (!this.doc) {
            if (isDevMode()) {
                console.error('Was not possible to access Document interface.');
            }

            return;
        }

        this.settings.initCommands = [...initialCommands, ...(this.settings.initCommands || [])];

        for (const command of this.settings.initCommands) {
            this.gtag(command.command, ...command.values);
        }

        const scriptElement: HTMLScriptElement = this.doc.createElement('script');
        this.renderer.setAttribute(scriptElement, 'id', 'ga-script');
        scriptElement.async = true;
        scriptElement.src = uri;
        const head: HTMLHeadElement = this.doc.getElementsByTagName('head')[0];
        head.appendChild(scriptElement);
    }

    removeGaScript(): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        const pixelElement = this.doc.getElementById('ga-script');
        if (pixelElement) {
            pixelElement.remove();
        }
    }

    private isLoaded(): boolean {
        if (isPlatformBrowser(this.platformId)) {
            const pixelElement = this.doc.getElementById('ga-script');
            return !!pixelElement;
        }
        return false;
    }

    private toKeyValue(map: Map<string, any>): { [param: string]: any } | void {
        return (map.size > 0)
            ? Array.from(map).reduce(
                (obj, [key, value]) => Object.defineProperty(obj, key, {value, enumerable: true}),
                {}
            )
            : undefined;
    }

    grantConsent() {
        this.gtagFn('consent', ...['update', {ad_storage: 'granted', analytics_storage: 'granted'}]);
    }

    revokeConsent() {
        this.gtagFn('consent', ...['update', {ad_storage: 'denied', analytics_storage: 'denied'}]);
    }

    gtag(...args: any[]) {
        try {
            if (this.gtagFn) {
                this.gtagFn(...args.filter(x => x !== undefined));
            } else {
                throw new Error("tag not available");
            }
        } catch (error) {
            if (isDevMode()) {
                console.error(error);
            }
        }
    }

    event(action: GaActionEnum | string, category?: string, label?: string, options?: {}) {
        try {
            const opt = new Map<string, any>([]);
            if (category) {
                opt.set('event_category', category);
            }

            if (label) {
                opt.set('event_label', label);
            }

            if (options) {
                Object
                    .entries(options)
                    .map(([key, value]) => opt.set(key, value));
            }

            const params = this.toKeyValue(opt);
            if (params) {
                this.gtag('event', action as string, params);
            } else {
                this.gtag('event', action as string);
            }
        } catch (error) {
            if (isDevMode()) {
                console.error(error);
            }
        }
    }

    pageView(path: string, title?: string, location?: string, options?: {}) {
        try {
            const opt = new Map<string, any>([['page_path', path]]);
            if (title) {
                opt.set('page_title', title);
            }
            if (location || this.documentReference) {
                opt.set('page_location', (location || this.documentReference.location.href));
            }
            if (options) {
                Object
                    .entries(options)
                    .map(([key, value]) => opt.set(key, value));
            }
            this.gtag('config', this.settings.trackingCode, this.toKeyValue(opt));
        } catch (error) {
            if (isDevMode()) {
                console.error(error);
            }
        }
    }

    addTransaction(id: number, options?: Map<string, any>) {
        try {
            const params = this.toKeyValue(options);
            if (params) {
                this.gtag('event', 'Ecommerce:addTransaction', params);
            } else {
                this.gtag('event', 'Ecommerce:addTransaction');
            }
        } catch (error) {
            if (isDevMode()) {
                console.error(error);
            }
        }
    }

    eventPurchaseEcommerce(basket, transaction_id, items) {
        this.event('purchase', 'Ecommerce', '', {
            transaction_id: transaction_id,
            affiliation: 'yorkstonesupplies.co.uk',
            value: basket.values.gross,
            tax: basket.values.vat,
            shipping: basket.values.delivery,
            discount: basket.values.rewardDiscount + basket.values.productDiscount + basket.values.voucherDiscount,
            coupon: basket.voucherCodeEntered,
            items: items
        });
    }

    eventConversionEcommerce(transaction_id, value) {
        this.event('conversion', 'Ecommerce', '', {
            send_to: 'AW-991523855/3rm1CIbBu44YEI_o5dgD',
            currency: 'GBP',
            transaction_id: transaction_id,
            value: value,
        });
    }
}
