import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        loadChildren: () =>
            import('./modules/dynamic-component-loader/plugin-loader.module').then((m) => m.PluginLoaderModule),
    },
    {
        path: '',
        loadChildren: () => import('./modules/default-layout/default-layout.module').then((m) => m.DefaultLayoutModule),
    },
    {
        path: '',
        loadChildren: () => import('./modules/blank-layout/blank-layout.module').then((m) => m.BlankLayoutModule),
    },
    {
        path: 'basket',
        loadChildren: () => import('./modules/basket-layout/basket-layout.module').then((m) => m.BasketLayoutModule),
    },
    {
        path: 'advice',
        loadChildren: () => import('./modules/advice/advice.module').then((m) => m.AdviceModule),
    },
    {
        path: 'case-studies',
        loadChildren: () => import('./modules/case-study/case-study.module').then((m) => m.CaseStudyModule),
    },
    {
        path: 'account',
        loadChildren: () => import('./modules/account/account.module').then((m) => m.AccountModule),
    },
    {
        path: 'products',
        loadChildren: () => import('./modules/product/product.module').then((m) => m.ProductModule),
    },
    {
        path: 'lead',
        loadChildren: () => import('./modules/lead-magnets/lead-magnets.module').then((m) => m.LeadMagnetsModule),
    },
    {
        path: ':route',
        loadChildren: () =>
            import('./modules/dynamic-component-loader/plugin-loader.module').then((m) => m.PluginLoaderModule),
    },
];

@NgModule({
    imports: [
        QuicklinkModule,
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            relativeLinkResolution: 'legacy',
            preloadingStrategy: QuicklinkStrategy,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
