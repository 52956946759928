<router-outlet></router-outlet>

<app-alert
    [data]="(alerts$ | async)"
    *ngIf="
        (alerts$ | async).errors.length ||
        (alerts$ | async).notifications.length ||
        (alerts$ | async).simple ||
        (alerts$ | async).confirmAction ||
        (alerts$ | async).customAlert
"></app-alert>

<div
    class="modal-backdrop fade show modal-slide-show"
    (click)="closeAll()"
    *ngIf="(asideOpen$ | async)">
    <app-processing *ngIf="(overlays$ | async).processing"></app-processing>

</div>
