import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Store } from '../store';
import { ToolsService } from './tools.service';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    env = environment;

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private store: Store,
        private toolsService: ToolsService,
    ) { }

    createSessionId() {
        const sessionId = this.toolsService.newUUID();
        sessionStorage.setItem('ysPublicSessionId', sessionId);
        return sessionId;
    }

    getSessionId(): string | undefined {
        return sessionStorage.getItem('ysPublicSessionId');
    }

    clearSessionId(): void {
        sessionStorage.removeItem('ysPublicSessionId');
    }

    createRemoteSession() {
        let sessionId = this.getSessionId();

        if (!sessionId) {
            sessionId = this.createSessionId();
        }

        const dataToSend = {
            sessionId,
        };
        return this.http.post(this.env.apiPath + 'session', dataToSend, this.apiService.getHttpOptions())
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                )
            );

    }

    trackClicks(e) {
        const user = this.store.selectForLocal('user');
        const activeRoute = this.store.selectForLocal('activeRoute');
        const sessionId = localStorage.getItem('tmpSessionId');
        const appVersion = localStorage.getItem('version');
        const dataToSend = {
            sessionId,
            user,
            activeRoute,
            appVersion,
            e,
            source: 'click'
        };
        return this.http.post(this.env.apiPath + 'users/clicks', dataToSend, this.apiService.getHttpOptions())
            .pipe(
                map((data: any) => {
                    return data;
                },
                    catchError(this.apiService.handleError)
                )
            );

    }
}
