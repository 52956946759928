import {Directive, ElementRef, Inject, Input, isDevMode, OnDestroy} from '@angular/core';
import {FacebookPixelService} from '../services/facebook-pixel.service';
import {FACEBOOK_PIXEL_SETTINGS_TOKEN} from '../token/facebook-pixel-settings-token';
import {fromEvent, Subscription} from 'rxjs';
import {FbqActionEnum} from '../enums/fb-actions.enum';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[fbqEvent]',
    exportAs: 'fbqEvent'
})
export class FbqEventDirective implements OnDestroy {

    constructor(
        @Inject(FACEBOOK_PIXEL_SETTINGS_TOKEN) private settings,
        private fbqService: FacebookPixelService,
        private readonly el: ElementRef,
    ) {
    }

    private bindSubscription?: Subscription;

    @Input() fbqEvent: FbqActionEnum | string;
    @Input() fbqContentName: string;
    @Input() fbqContentCategory: string;
    @Input() fbqContentIds: [];
    @Input() fbqContentType: string;
    @Input() fbqValue: number;
    @Input() fbqCurrency: number;

    private _fbqBind: string;

    @Input() set fbqBind(fbqBind: string) {
        if (this.bindSubscription) {
            this.bindSubscription.unsubscribe();
        }

        this._fbqBind = fbqBind;
        this.bindSubscription = fromEvent(this.el.nativeElement, fbqBind).subscribe(() => this.trigger());
    }

    get fbqBind(): string {
        return this._fbqBind;
    }

    ngOnDestroy() {
        if (this.bindSubscription) {
            this.bindSubscription.unsubscribe();
        }
    }

    protected trigger() {
        try {

            if (!this.fbqEvent) {
                if (isDevMode()) {
                    console.error('You must provide a fbqAction attribute to identify this event.');
                }
            }

            const properties = {
                content_name: this.fbqContentName,
                content_category: this.fbqContentCategory,
                content_ids: this.fbqContentIds,
                content_type: this.fbqContentType,
                value: this.fbqValue,
                currency: this.fbqCurrency,
            };

            if (this.fbqEvent in FbqActionEnum) {
                this.fbqService
                    .event(
                        this.fbqEvent,
                        properties
                    );
            } else {
                this.fbqService
                    .eventCustom(
                        this.fbqEvent,
                        properties
                    );
            }
        } catch (error) {
            if (isDevMode()) {
                console.error(error);
            }
        }
    }

}
