import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Inject,
    Input,
    NgModule,
    OnDestroy,
    OnInit, PLATFORM_ID,
    ViewChild
} from '@angular/core';
import KeenSlider from 'keen-slider';
import {Store} from '../../store';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {DirectivesModule} from '../../directives/directives.module';
import { iif, Observable } from "rxjs";
import { debounce } from 'lodash';

@Component({
    selector: 'app-trustpilot-review-slider',
    templateUrl: './trustpilot-review-slider.component.html',
    styleUrls: ['./trustpilot-review-slider.component.scss']
})
export class TrustpilotReviewSliderComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() backgroundColour = '#52616c';
    @Input() backgroundImage = '';
    @Input() arrowColour = '#fff';

    deviceType$ = this.store.select<string>('deviceType');
    tiles = [
        {
            header: 'Hebden buff',
            review: 'I Had 6 pieces of 400 mm x 1000 mm hebden buff sawn yorkstone delivered which was used for seating areas, it was excellent quality and was delivered on time, good communications and will use again.<br />Thanks'
        },
        {
            header: 'Knowledgable and helpful staff in the…',
            review: 'Knowledgable and helpful staff in the sales office. Quick delivery which turned up at the designated time. Stunning product that exceeded my expectations.'
        }
    ];
    spacing = 15;
    slidesPerView = 1;
    showDots = false;
    showNav = true;
    showExpandArrows = false;

    @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;

    currentSlide = 1;
    dotHelper: Array<number> = [];
    slider: any = null;
    fullScreen = false;
    innerWidth$ = this.store.select<number>('innerWidth');

    arrowLeftSrc = 'assets/images/icon-arrow-slider-left.svg';
    arrowRightSrc = 'assets/images/icon-arrow-slider-right.svg';
    private isBrowser: boolean;

    constructor(
        private store: Store,
        @Inject(PLATFORM_ID) platformId: {}
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.initSlider = debounce(this.initSlider, 50);
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.initSlider();
    }

    private initSlider() {
        iif(
            () => this.isBrowser,
            new Observable((observer) => {
                this.slider = new KeenSlider(this.sliderRef.nativeElement, {
                    slidesPerView: this.slidesPerView,
                    spacing: this.spacing,
                    slideChanged: s => {
                        this.currentSlide = s.details().relativeSlide;
                    }
                });
                if (this.slider.details().size) {
                    const dots = (+this.slider.details().size - +this.slidesPerView) + 1;
                    this.dotHelper = dots < 0 ? [] : [...Array(dots)];
                } else {
                    this.dotHelper = [];
                }
                observer.next();
                observer.complete();
            })
        ).subscribe();
    }

    prevSlide(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.clearSelection();
        this.slider.prev();
        if (this.currentSlide === 1) {
            this.arrowLeftSrc = 'assets/images/icon-arrow-slider-left.svg';
        }
        return false;
    }

    nextSlide(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.clearSelection();
        this.slider.next();
        if ((this.currentSlide + this.slidesPerView) + 1 === this.tiles.length) {
            this.arrowRightSrc = 'assets/images/icon-arrow-slider-right.svg';
        }
        return false;
    }

    clearSelection() {
        const sel = window.getSelection();
        if (sel && sel.empty) {
            sel.removeAllRanges();
        }
    }

    arrowPreviousState(reset) {
        if (this.currentSlide !== 0 && reset) {
            this.arrowLeftSrc = 'assets/images/icon-arrow-slider-left-bold.svg';
        } else {
            this.arrowLeftSrc = 'assets/images/icon-arrow-slider-left.svg';
        }
    }

    arrowNextState(reset) {
        if ((this.currentSlide + this.slidesPerView) !== this.tiles.length && reset) {
            this.arrowRightSrc = 'assets/images/icon-arrow-slider-right-bold.svg';
        } else {
            this.arrowRightSrc = 'assets/images/icon-arrow-slider-right.svg';
        }
    }

    ngOnDestroy(): void {
        if (this.slider) {
            this.slider.destroy();
        }
    }
}

@NgModule({
    declarations: [TrustpilotReviewSliderComponent],
    imports: [
        CommonModule,
        AngularSvgIconModule,
        DirectivesModule
    ],
    exports: [
        TrustpilotReviewSliderComponent
    ]
})

export class TrustpilotReviewSliderModule {
}
