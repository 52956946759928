import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie';
import { iif, Observable, of } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { environment, environment as env } from '../../environments/environment';
import { ApiAuthHttpClient } from '../http/ApiAuthHttpClient';
import { ApiHttpClient } from '../http/ApiHttpClient';
import { CompanyServiceType } from '../models/company/company-service-type';
import { CompanyType } from '../models/company/company-type';
import { USER_COOKIE } from '../shared/constants';
import { Store } from '../store';
import { ApiService } from './api.service';
import { ToolsService } from './tools.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(
        private apiService: ApiService,
        private apiHttp: ApiHttpClient, // errors and single request handled
        private apiAuthHttp: ApiAuthHttpClient, // errors, single and authentication request handled
        private store: Store,
        private cookieService: CookieService,
        private toolsService: ToolsService,
        @Inject(PLATFORM_ID) platformId: {}
    ) {
        if (isPlatformBrowser(platformId)) {
            if (environment.version !== localStorage.getItem('version')) {
                localStorage.clear();
                this.cookieService.removeAll();
            }
            localStorage.setItem('version', environment.version);
        }
    }

    logSession(userId, sessionId) {
        const device = this.store.selectForLocal('device');

        const dataToSend = {
            device: device.info,
            userId,
            sessionId,
        };

        return this.apiAuthHttp.post(env.apiPath + 'users/device', dataToSend).pipe(map((data: any) => data));
    }

    postcodeLookup(postcode) {
        return this.apiAuthHttp.get(`${env.apiPath}postcode/${postcode}`).pipe(map(({ data }: any) => data));
    }

    countryList() {
        return this.apiAuthHttp.get(`${env.apiPath}countries`).pipe(map((data: any) => data.data));
    }

    createUserNew(user) {
        const dataToSend = {
            user,
            createdBy: this.store.selectForLocal('user'),
        };
        return this.apiAuthHttp.post(env.apiPath + 'users/new', dataToSend);
    }

    async createAddressNew(address, user) {
        const dataToSend = {
            address,
            user,
            createdBy: this.store.selectForLocal('user'),
        };
        const result: any = await this.apiAuthHttp.post(env.apiPath + 'users/address/new', dataToSend).toPromise();

        return result.data;
    }

    async createCompanyNew(company, user) {
        const dataToSend = {
            company,
            user,
            createdBy: this.store.selectForLocal('user'),
        };
        const result: any = await this.apiAuthHttp.post(env.apiPath + 'users/company/new', dataToSend).toPromise();
        return result.data;
    }

    getCompanyTypes(): Observable<CompanyType[]> {
        return this.apiAuthHttp
            .get<{ data: CompanyType[] }>(`${env.apiPath}users/company/type/all`)
            .pipe(map(({ data }) => data));
    }

    saveSelectedCompanyTypes(companyTypes: string[]): Observable<void> {
        return this.apiAuthHttp.post<void>(`${env.apiPath}users/company/type/save-selected`, companyTypes);
    }

    getSelectedCompanyTypes(): Observable<string[]> {
        return this.apiAuthHttp
            .get<{ data: { companyTypeId: string }[] }>(`${env.apiPath}users/company/type/get-selected`)
            .pipe(map(({ data }) => data.map((d) => d.companyTypeId)));
    }

    getCompanyServiceTypes(): Observable<CompanyServiceType[]> {
        return this.apiAuthHttp
            .get<{ data: CompanyServiceType[] }>(`${env.apiPath}users/company/service-type/all`)
            .pipe(map(({ data }) => data));
    }

    saveSelectedCompanyServiceTypes(companyServiceTypes: string[]): Observable<void> {
        return this.apiAuthHttp.post<void>(
            `${env.apiPath}users/company/service-type/save-selected`,
            companyServiceTypes
        );
    }

    getSelectedCompanyServiceTypes(): Observable<string[]> {
        return this.apiAuthHttp
            .get<{ data: { companyServiceTypeId: string }[] }>(`${env.apiPath}users/company/service-type/get-selected`)
            .pipe(map(({ data }) => data.map((d) => d.companyServiceTypeId)));
    }

    RegistrationAdditionalDetails(address, company, user) {
        const dataToSend = {
            address,
            company,
            user,
        };
        return this.apiAuthHttp
            .post(env.apiPath + 'users/company/additional', dataToSend)
            .pipe(map((data: any) => data));
    }

    createUser(user) {
        return this.apiAuthHttp.post(env.apiPath + 'users/signup', user).pipe(map((data: any) => data));
    }

    async createUserAsync(user) {
        const result: any = await this.apiAuthHttp.post(env.apiPath + 'users/signup', user).toPromise();
        return result.data;
    }

    async checkUserExists(email) {
        const result: any = await this.apiAuthHttp.get(env.apiPath + 'users/exists/' + email).toPromise();
        return result.data;
    }

    checkReferralCode(referralCode, referralEmail) {
        const dataToSend = {
            referralCode,
            referralEmail,
        };
        return this.apiAuthHttp
            .post(env.apiPath + 'users/referralcode/check', dataToSend)
            .pipe(map((data: any) => data));
    }

    async createGuest(user) {
        const result: any = await this.apiAuthHttp.post(env.apiPath + 'users/signup', user).toPromise();
        return result.data;
    }

    async createAddress(address, createdBy) {
        const dataToSend = {
            address,
            createdBy,
        };
        const result: any = await this.apiAuthHttp.post(env.apiPath + 'users/address', dataToSend).toPromise();
        return result;
    }

    updateAddress(address) {
        const user = this.store.selectForLocal('user');
        const dataToSend = {
            address,
            user,
        };
        return this.apiAuthHttp
            .put(env.apiPath + 'users/address/' + address.id, dataToSend)
            .pipe(map((data: any) => data));
    }

    updatePrimaryUser(companyId, userId) {
        const dataToSend = {
            companyId,
            userId,
        };
        return this.apiAuthHttp
            .put(env.apiPath + 'users/company/' + companyId + '/primaryuser', dataToSend)
            .pipe(map((data: any) => data));
    }

    createCompany(company, createdBy) {
        const dataToSend = {
            company,
            createdBy,
        };
        return this.apiAuthHttp.post(env.apiPath + 'users/company', dataToSend).pipe(map((data: any) => data));
    }

    getByToken() {
        return this.apiAuthHttp.get(env.apiPath + 'users/profile').pipe(
            mergeMap((data: any) =>
                iif(
                    () => !data.data?.blacklisted, // not sure if it's sufficient enough here but I need this at least here
                    of(data),
                    throwError(() => new Error(`Really bad thing had happened!!`))
                )
            ),
            tap((data: any) => {
                this.store.set('user', data.data);
                this.cookieService.put(USER_COOKIE, JSON.stringify(data.data));
                this.toolsService.setSessionId(data.data.id);
                localStorage.setItem('user', data.data.id);
            })
        );
    }

    update(user) {
        return this.apiAuthHttp.put(env.apiPath + 'users/' + user.id, user).pipe(
            map(async (data: any) => {
                this.store.set('user', user);
                this.cookieService.put(USER_COOKIE, JSON.stringify(user));
                // await this.cookieService.setUser(user);
                localStorage.setItem('user', user.id);
                // await this.cookieService.set('user', user);
                return data;
            })
        );
    }

    updateContact(user) {
        return this.apiAuthHttp
            .put(env.apiPath + 'users/contact/' + user.id, user)
            .pipe(map(async (data: any) => data));
    }

    findAllByCompany(params) {
        const dataToSend = {
            params,
        };
        return this.apiAuthHttp.post(env.apiPath + 'users/search/users', dataToSend).pipe(map((data: any) => data));
    }

    findOne(id) {
        return this.apiAuthHttp.get(env.apiPath + 'users/' + id).pipe(map((data: any) => data));
    }

    delete(id) {
        return this.apiAuthHttp.delete(env.apiPath + 'users/' + id).pipe(map((data: any) => data));
    }

    search() {
        const companyUsers = this.store.selectForLocal('companyUsers');
        const user = this.store.selectForLocal('user');
        companyUsers.params.accountType = user.accountType;
        companyUsers.params.companyId = user.company.id;
        const dataToSend = {
            params: companyUsers.params,
        };
        return this.apiAuthHttp.post(env.apiPath + 'users/search', dataToSend).pipe(
            map((data: any) => {
                companyUsers.data = data.data.data;

                companyUsers.params.pages = Math.ceil(data.data.totalRecords / companyUsers.params.limit);
                companyUsers.params.totalRecords = data.data.totalRecords;
                companyUsers.params.pageArray = [];
                for (let i = 0; i < companyUsers.params.pages; i++) {
                    companyUsers.params.pageArray.push(i);
                }
                this.store.set('companyUsers', companyUsers);

                return data;
            })
        );
    }

    searchCustomers(params) {
        const dataToSend = {
            params,
        };
        return this.apiAuthHttp.post(env.apiPath + 'users/search/customers', dataToSend).pipe(map((data: any) => data));
    }

    findAddressesByCustomer(userId) {
        return this.apiAuthHttp.get(env.apiPath + 'users/addresses/' + userId).pipe(
            map((data: any) => {
                this.store.set('deliveryAddresses', data.data);
                return data;
            })
        );
    }

    addressDelete(addressId) {
        return this.apiAuthHttp.delete(env.apiPath + 'users/address/' + addressId).pipe(map((data: any) => data));
    }

    searchCompanies(params) {
        const dataToSend = {
            params,
        };
        return this.apiAuthHttp.post(env.apiPath + 'users/search/companies', dataToSend).pipe(map((data: any) => data));
    }

    areasCovered(params) {
        const dataToSend = {
            params,
        };
        return this.apiAuthHttp
            .post(env.apiPath + 'users/search/companies/areascovered', dataToSend)
            .pipe(map((data: any) => data));
    }

    getCompanyAreasCovered(companyId) {
        return this.apiAuthHttp
            .get(env.apiPath + 'users/search/companies/areascovered/' + companyId)
            .pipe(map((data: any) => data));
    }

    searchSupplierCollectionCosts(params) {
        const dataToSend = {
            params,
        };
        return this.apiAuthHttp
            .post(env.apiPath + 'courier/supplier/collection/', dataToSend)
            .pipe(map((data: any) => data));
    }

    updateSupplierCollectionCosts(supplier, courierId) {
        const dataToSend = {
            courierId,
            supplier,
        };

        return this.apiAuthHttp
            .put(env.apiPath + 'courier/supplier/collection', dataToSend)
            .pipe(map((data: any) => data));
    }

    disablePremiumAnimation(companyId: number) {
        return this.apiAuthHttp
            .put(env.apiPath + 'users/premium-animation/' + companyId, {})
            .pipe(map((data: any) => data));
    }

    async createLeadBasedOnGuestData(leadData) {
        this.apiAuthHttp
            .post(env.apiPath + 'users/create-lead', leadData)
            .pipe(map((data: any) => data))
            .subscribe();
    }
}
