import packageJson from '../../package.json';

export const environment = {
    version: packageJson.version,
    production: true,
    imageUploadPath: 'https://www.yorkstonesupplies.co.uk/api/',
    apiPath: 'https://www.yorkstonesupplies.co.uk/api/',
    socketPath: 'https://api.ysprod.co.uk/',
    stripeKey: 'pk_live_hwa98cvhnqasyiIXBDiJccPz',
    ga: 'UA-33766575-1',
    adsCode: 'AW-991523855',
    phoneTrackingID: 'AW-991523855/mpPlCJDQ7ZEYEI_o5dgD',
    phoneTrackingNumber: '01483 930 104',
    fbPixelId: '743908379772673',
    emulateSslForLocalDev: true,
    hotjarId: '3275523',
    domain: 'ys',
    allowSeparateShipments: false,
    sentryDNS: 'https://5328239666bc44fbbbc0193cdb3dc78d@o4503999080300544.ingest.sentry.io/4504322998272000'
};
