import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'strReplace'}) // log variable into the console

export class StrReplacePipe implements PipeTransform {
    transform($value: string, $what: string, $with: string = "") {
        if (!$value) return $value;
        return $value.replace($what, $with);
    }
}
