import {Inject, ModuleWithProviders, NgModule, PLATFORM_ID} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FACEBOOK_PIXEL_SETTINGS_TOKEN} from './token/facebook-pixel-settings-token';
import {FacebookPixelService} from './services/facebook-pixel.service';
import {FbqEventDirective} from './directives/fbq-event.directive';

@NgModule({
    declarations: [
        FbqEventDirective,
    ],
    exports: [
        FbqEventDirective
    ],
    imports: [
        CommonModule
    ]
})
export class FacebookPixelModule {
    constructor(
        private fbqService: FacebookPixelService,
        @Inject(FACEBOOK_PIXEL_SETTINGS_TOKEN) private readonly settings,
    ) {

    }

    static forRoot(pixelId: string, isEnabled?: boolean): ModuleWithProviders<FacebookPixelModule> {
        return {
            ngModule: FacebookPixelModule,
            providers: [
                {
                    provide: FACEBOOK_PIXEL_SETTINGS_TOKEN,
                    useValue: {
                        pixelId,
                        isEnabled
                    }
                },
            ]
        };
    }
}
