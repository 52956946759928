import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { Store } from '../store';
import { map } from 'rxjs/operators';
import { ApiAuthHttpClient } from '../http/ApiAuthHttpClient';

@Injectable({
    providedIn: 'root',
})
export class RewardService {
    env = environment;

    constructor(private apiService: ApiService, private http: ApiAuthHttpClient, private store: Store) {}

    userSummary(userId) {
        return this.http.get(this.env.apiPath + 'rewards/user/summary/' + userId).pipe(map(({ data }: any) => data));
    }

    rewardsTriggered(params) {
        const dataToSend = {
            params,
        };
        return this.http.post(this.env.apiPath + 'rewards/triggered', dataToSend).pipe(map(({ data }: any) => data));
    }

    getUserGiftTypes(userId) {
        return this.http.get(this.env.apiPath + 'rewards/user/gifttypes/' + userId).pipe(map(({ data }: any) => data));
    }

    addUserGiftType(typeId, userId) {
        return this.http
            .put(this.env.apiPath + 'rewards/user/gifttypes/' + typeId + '/' + userId, {})
            .pipe(map(({ data }: any) => data));
    }

    removeUserGiftType(typeId, userId) {
        return this.http
            .delete(this.env.apiPath + 'rewards/user/gifttypes/' + typeId + '/' + userId)
            .pipe(map(({ data }: any) => data));
    }

    rewardTriggersByUser(userId) {
        return this.http.get(this.env.apiPath + 'rewards/triggers/' + userId).pipe(map(({ data }: any) => data));
    }

    createRewardTrigger(trigger, user) {
        const dataToSend = {
            trigger,
            user,
        };
        return this.http
            .post(this.env.apiPath + 'rewards/trigger/reward', dataToSend, {})
            .pipe(map(({ data }: any) => data));
    }

    inviteUserByEmail(emails, user) {
        const dataToSend = {
            emails,
            user,
        };
        return this.http.post(this.env.apiPath + 'rewards/email/invite', dataToSend).pipe(map(({ data }: any) => data));
    }
}
