import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DirectivesModule } from '../directives/directives.module';
import { AddedToBasketComponent } from '../modules/product/added-to-basket/added-to-basket.component';
import { PipesModule } from '../pipes/pipes.module';
import { AddressFormComponent } from './address-form/address-form.component';
import { AlertComponent } from './alert/alert.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CompetitionEntryComponent } from './competition-entry/competition-entry.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { FixedElementsComponent } from './fixed-elements/fixed-elements.component';
import { GrowlerComponent } from './growler/growler.component';
import { ImageSliderKeenComponent } from './image-slider-keen/image-slider-keen.component';
import { InlineSpinnerComponent } from './inline-spinner/inline-spinner.component';
import { MaxQtyModalComponent } from './max-qty-modal/max-qty-modal.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ProcessingComponent } from './processing/processing.component';
import { RatingIconsComponent } from './rating-icons/rating-icons.component';
import { TileSliderKeenComponent } from './tile-slider-keen/tile-slider-keen.component';
import { YSPhoneInputComponent } from './ys-phone-input/ys-phone-input.component';
import { PluginLoaderGlobalComponent } from './plugin-loader-global/plugin-loader-global.component';

@NgModule({
    declarations: [
        PaginationComponent,
        GrowlerComponent,
        ConfirmationComponent,
        AlertComponent,
        RatingIconsComponent,
        ProcessingComponent,
        ImageSliderKeenComponent,
        BreadcrumbComponent,
        TileSliderKeenComponent,
        FileUploaderComponent,
        FixedElementsComponent,
        AddressFormComponent,
        CompetitionEntryComponent,
        AddedToBasketComponent,
        MaxQtyModalComponent,
        InlineSpinnerComponent,
        YSPhoneInputComponent,
        PluginLoaderGlobalComponent,
    ],
    imports: [
        CommonModule,
        EditorModule,
        RouterModule,
        InViewportModule,
        AngularSvgIconModule,
        DirectivesModule,
        PipesModule,
        ReactiveFormsModule,
        FormsModule,
        NgSelectModule,
        BsDropdownModule.forRoot(),
        NgxIntlTelInputModule,
    ],
    exports: [
        PaginationComponent,
        GrowlerComponent,
        ConfirmationComponent,
        AlertComponent,
        RatingIconsComponent,
        ProcessingComponent,
        ImageSliderKeenComponent,
        EditorModule,
        PipesModule,
        BreadcrumbComponent,
        InlineSpinnerComponent,
        TileSliderKeenComponent,
        FileUploaderComponent,
        FixedElementsComponent,
        AddressFormComponent,
        CompetitionEntryComponent,
        AddedToBasketComponent,
        MaxQtyModalComponent,
        BsDropdownModule,
        YSPhoneInputComponent,
        PluginLoaderGlobalComponent,
    ],
})
export class SharedModule { }
