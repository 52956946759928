import { StaffComponent } from './cms/staff/staff.component';
import { BrowserModule, BrowserTransferStateModule, TransferState } from '@angular/platform-browser';
import { ErrorHandler, APP_INITIALIZER, Inject, Injector, NgModule, PLATFORM_ID, isDevMode } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Store } from './store';
import { HttpClient, HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { ToastrModule } from 'ngx-toastr';
import { QuestionnaireModule } from './modules/questionnaire/questionnaire.module';
import { FaqComponent } from './cms/faq/faq.component';
import { WorkedWithComponent } from './cms/plugins/worked-with/worked-with.component';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { DirectivesModule } from './directives/directives.module';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { AngularSvgIconModule, SvgLoader } from 'angular-svg-icon';
import { SvgBrowserLoader } from './utils/svg-browser.loader';
import { SvgServerLoader } from './utils/svg-server.loader';
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { TrustpilotReviewSliderModule } from './shared/trustpilot-review-slider/trustpilot-review-slider.component';
import { ContactCtaModule } from './shared/contact-cta/contact-cta.component';
import { CookieModule } from 'ngx-cookie';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { GoogleAnalyticsModule } from './modules/tracking/google-analytics.module';
import { FacebookPixelModule } from './modules/tracking/facebook-pixel.module';
import { GoogleAnalyticsService } from './modules/tracking/services/google-analytics.service';
import { CookieConsentService } from './modules/tracking/services/cookie-consent.service';
import { GOOGLE_ANALYTICS_SETTINGS_TOKEN } from './modules/tracking/token/google-analytics-settings-token';
import { FacebookPixelService } from './modules/tracking/services/facebook-pixel.service';
import { FACEBOOK_PIXEL_SETTINGS_TOKEN } from './modules/tracking/token/facebook-pixel-settings-token';
import { Router } from '@angular/router';
import { YsErrorHandler } from './YsErrorHandler';
import { HttpModule } from './http/http.module';
import { AppNameInterceptor } from './http/app-name.interceptor';
export function svgLoaderFactory(http: HttpClient, transferState: TransferState, platformId: any): SvgServerLoader | SvgBrowserLoader {
    if (isPlatformServer(platformId)) {
        return new SvgServerLoader('../browser/assets/images', transferState);
    } else {
        return new SvgBrowserLoader(http, transferState);
    }
}
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { ErrorRequestInterceptor } from './http/error-request.interceptor';

const config: SocketIoConfig = { url: environment.socketPath, options: {} };

@NgModule({
    declarations: [
        AppComponent,
        FaqComponent,
        WorkedWithComponent,
        StaffComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserTransferStateModule,
        TransferHttpCacheModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        CookieModule.forRoot(),
        SharedModule,
        QuestionnaireModule,
        ToastrModule.forRoot({
            preventDuplicates: true,
            progressBar: true,
            closeButton: true,
        }),
        InViewportModule,
        DirectivesModule,
        AngularSvgIconModule.forRoot({
            loader: {
                provide: SvgLoader,
                useFactory: svgLoaderFactory,
                deps: [HttpClient, TransferState, PLATFORM_ID],
            },
        }),
        TrustpilotReviewSliderModule,
        ContactCtaModule,
        SocketIoModule.forRoot(config),
        GoogleAnalyticsModule.forRoot(environment.ga, environment.adsCode, environment.phoneTrackingID, environment.phoneTrackingNumber, [], true),
        FacebookPixelModule.forRoot(environment.fbPixelId, true),
        HttpModule
    ],
    providers: [
        Store,
        { provide: ErrorHandler, useClass: YsErrorHandler },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppNameInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorRequestInterceptor,
            multi: true,
        },

    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    static injector: Injector;

    constructor(
        private gaService: GoogleAnalyticsService,
        private fbqService: FacebookPixelService,
        private consentService: CookieConsentService,
        private router: Router,
        @Inject(GOOGLE_ANALYTICS_SETTINGS_TOKEN) private readonly gaSettings,
        @Inject(FACEBOOK_PIXEL_SETTINGS_TOKEN) private readonly fbqSettings,
        @Inject(PLATFORM_ID) platformId: {},
        injector: Injector
    ) {
        AppModule.injector = injector;
        this.checkForGaConsent();
        this.checkForFbqConsent();
    }

    checkForGaConsent() {
        if (this.gaSettings.isEnabled) {
            if (this.consentService.getGaEnabledCookie() === '0') {
                return;
            }
            this.gaService.initialize();
        }
    }

    checkForFbqConsent() {
        if (this.fbqSettings.isEnabled) {
            if (this.consentService.getFbqEnabledCookie() === '0') {
                return;
            }
            this.fbqService.initialize();
        }
    }
}
