import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {Router} from '@angular/router';
import {Store} from '../store';
import {CookieService} from 'ngx-cookie';
import {IS_LOGGED_IN, USER_COOKIE} from '../shared/constants';

@Injectable()
export class ErrorRequestInterceptor implements HttpInterceptor {
    constructor(private router: Router,
        private store: Store,
        private cookieService: CookieService,
        ) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
        return next.handle(request)
            .pipe(
                catchError((error) => {
                    if (error.error) {
                        console.error('An error occurred:', error.error.message);
                    } else {
                        console.error(
                            `Backend returned code ${error.status}, ` +
                            `body was: ${error.error}`);
                    }
                    if (error.error?.code === 'AUTH-0002') {
                        this.store.set('user', undefined);
                        this.store.set('token', undefined);
                        this.cookieService.remove(IS_LOGGED_IN);
                        this.cookieService.remove(USER_COOKIE);
                       this.router.navigate(['/login'], {
                        queryParams: {
                            resetLogin: true
                        }
                       });
                       return of({});
                    }
                    const message = error?.error?.message || "Something bad happened; please try again later.";
                    return throwError({ error: { message, code: error?.error?.code, status: error?.error?.status }, status: error?.status });
                })
            );
    }
}
