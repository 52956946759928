import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AwesomeTooltipDirective} from './tooltip/tooltip.directive';
import {AwesomeTooltipComponent} from './tooltip/tooltip.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {RouteLinkSwipeProtectedDirective} from './route-link-swipe-protected/route-link-swipe-protected.directive';
import {DropdownDirective} from './dropdown/dropdown.directive';
import { ScrsetDirective } from './srcset/scrset.directive';
import {FileDndDirective} from '../shared/directives/file-uploader-dnd.directive';
import { MissingImageUrlDirective } from './image/missing-image-url.directive';
import { LinkToRouteDirective } from './link-to-route/link-to-route.directive';

@NgModule({
    declarations: [
        AwesomeTooltipDirective,
        AwesomeTooltipComponent,
        RouteLinkSwipeProtectedDirective,
        DropdownDirective,
        ScrsetDirective,
        FileDndDirective,
        MissingImageUrlDirective,
        LinkToRouteDirective
    ],
    imports: [
        CommonModule,
        OverlayModule,
    ],
    exports: [
        AwesomeTooltipDirective,
        RouteLinkSwipeProtectedDirective,
        DropdownDirective,
        ScrsetDirective,
        FileDndDirective,
        MissingImageUrlDirective,
        LinkToRouteDirective
    ],
    entryComponents: [
        AwesomeTooltipComponent,
    ],
})
export class DirectivesModule {
}
