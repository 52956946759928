import { ApiService } from './services/api.service';
import { ErrorHandler, Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { environment as env } from '../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Store } from "./store";
import { catchError } from "rxjs/operators";
import { isPlatformBrowser } from "@angular/common";

@Injectable()
export class YsErrorHandler implements ErrorHandler {
    static errorWasReported = false;

    private isBrowser = false;

    constructor(
        private apiService: ApiService,
        private injector: Injector,
        @Inject(PLATFORM_ID) platformId: {}
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    handleError(error: any): void {
        if (error.toString().indexOf('ExpressionChangedAfterItHasBeenCheckedError') !== -1) {
            if (YsErrorHandler.errorWasReported === false) { // this is needed until upgrade to ng13 I think
                console.error("Suppressing error `ExpressionChangedAfterItHasBeenCheckedError`");
                YsErrorHandler.errorWasReported = true;
            }
            return;
        }

        if (!this.isBrowser) {
            return console.error(error);
        }

        // this.consoleReport(error);
        console.log(error.stack)
        let errorString = '';
        let errorStack = '';
        if (error.stack) {
            errorStack = error.stack;
        }
        if (error.error) {
            if (error.error.message) {
                errorString = error.error.message;
            } else {
                errorString = error;
            }
        } else {
            if (error.message) {
                errorString = error.message;
            } else {
                errorString = error;
            }
        }

        const http = this.injector.get(HttpClient);
        const store = this.injector.get(Store);
        const user = store.selectForLocal('user');
        const sessionId = store.selectForLocal('sessionId');
        const activeRoute = store.selectForLocal('activeRoute');

        http
            .post(
                `${env.apiPath}error-reporting`,
                {
                    user: user ? user.id : '',
                    siteArea: "Public",
                    sessionId,
                    activeRoute,
                    url: window.location.href.toString(),
                    errorType: document.title,
                    description: error.message,
                    error: errorString,
                    errorStack,
                    agent: navigator.userAgent,
                }
                , this.apiService.getHttpOptions()
            )
            .pipe(
                catchError(this.consoleReport.bind(this))
            )
            .subscribe();
        this.consoleReport(error)
    }

    private consoleReport(error: any): void {
        if (error.error) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error('An error occurred:', error);
        }

        throw error;
    }
}
