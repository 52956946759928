import {Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'img, [appScrset]'
})
export class ScrsetDirective implements OnInit, OnChanges {
    @Input() appScrset;
    @Input() width;
    @Input() height;

    constructor(
        private el: ElementRef<HTMLImageElement>,
        private renderer: Renderer2,
    ) {
        if (this.el.nativeElement.tagName === 'IMG') {
            this.el.nativeElement.setAttribute('loading', 'lazy');
        }
    }

    ngOnInit() {
        this.renderImage();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes) {
            this.renderImage();
        }
    }

    renderImage() {
        if (this.appScrset && this.el.nativeElement) {
            if (!this.width) {
                this.width = this.el.nativeElement.width;
            }

            if (!this.height) {
                this.height = this.el.nativeElement.height;
            }

            if (this.el.nativeElement.tagName === 'IMG') {
                if (this.width) {
                    let srcSet = '';

                    srcSet = srcSet.concat(this.appScrset + `?width=` + this.width + ` ` + this.width + `w, `);

                    if (349 <= this.width) {
                        srcSet = srcSet.concat(this.appScrset + `?width=349 349w,`);
                    }

                    if (575 <= this.width) {
                        srcSet = srcSet.concat(this.appScrset + `?width=575 575w,`);
                    }

                    if (767 <= this.width) {
                        srcSet = srcSet.concat(this.appScrset + `?width=767 767w,`);
                    }

                    if (991 <= this.width) {
                        srcSet = srcSet.concat(this.appScrset + `?width=991 991w,`);
                    }

                    if (1199 <= this.width) {
                        srcSet = srcSet.concat(this.appScrset + `?width=1199 1199w,`);
                    }

                    if (1399 <= this.width) {
                        srcSet = srcSet.concat(this.appScrset + `?width=1399 1399w,`);
                    }

                    if (1900 <= this.width) {
                        srcSet = srcSet.concat(this.appScrset + `?width=1900 1900w`);
                    }

                    this.renderer.setAttribute(this.el.nativeElement, 'srcset', srcSet);
                    this.renderer.setAttribute(this.el.nativeElement, 'sizes', '(max-width: ' + this.width + ') 100vw, ' + this.width);
                    if (this.width) {
                        this.renderer.setAttribute(this.el.nativeElement, 'src', this.appScrset + `?width=` + this.width);
                    }
                }
            } else {
                const srcSet =
                    `-webkit-image-set(` +
                    `url("` + this.appScrset + `?width=` + this.width + `") 1x, ` +
                    `url("` + this.appScrset + `?width=` + this.width * 2 + `") 2x, ` +
                    `)`;
                this.renderer.setStyle(this.el.nativeElement, 'background-image', srcSet);
            }

        }
    }
}
