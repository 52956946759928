import { Component, ElementRef, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ToolsService } from './services/tools.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Store } from './store';
import { OverlayService } from './services/overlay.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { CookieService } from 'ngx-cookie';
import { ChatService } from './services/chat.service';
import { UserService } from './services/user.service';
import { NotificationService } from './services/notification.service';
import { IS_LOGGED_IN, USER_COOKIE } from './shared/constants';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { CmsService } from './services/cms.service';
import { AuthService } from './services/auth.service';
import { Subscription, fromEvent } from 'rxjs';
import { SessionService } from './services/session.service';
import { BasketService } from './services/basket.service';

declare let gtag: (name: string, id: string, config: any) => void;
declare let hj: (event: string, p2: string, p3: any) => void;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'public';
    currentURL = '';
    asideOpen$ = this.store.select<boolean>('asideOpen');
    alerts$ = this.store.select<any>('alerts');
    overlays$ = this.store.select<any>('overlays');
    isBrowser = false;
    userId = '';
    sessionId = '';
    clickSubscription: Subscription;

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.getDeviceSize();
    }

    @HostListener('keydown.escape', ['$event']) onKeydownHandler() {
        this.overlayService.closeAll();
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event: Event) {
        this.chatService.sendOfflineSignal();
    }

    constructor(
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private cookieService: CookieService,
        private toolsService: ToolsService,
        private deviceService: DeviceDetectorService,
        private overlayService: OverlayService,
        private chatService: ChatService,
        private userService: UserService,
        private notificationService: NotificationService,
        private cmsService: CmsService,
        private authService: AuthService,
        private sessionService: SessionService,
        private basketService: BasketService,
        private el: ElementRef,
        @Inject(DOCUMENT) private document: Document,
        @Inject(PLATFORM_ID) platformId: {},
        private meta: Meta
    ) {
        this.isBrowser = isPlatformBrowser(platformId);

        this.overlayService.initSpinner().subscribe((val) => {
            if (val) {
                this.isBrowser && (document.getElementById('loader').style.display = 'none');
            }
        });

        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                let activeRoute = val.url.replace(/^\/|\/$/g, '');
                this.overlayService.closeAll();

                if (activeRoute === '') {
                    activeRoute = 'home';
                }

                this.store.set('activeRoute', activeRoute);
                const navigationHistory = this.store.selectForLocal('navigationHistory');
                this.currentURL = val.urlAfterRedirects;

                if (
                    val.urlAfterRedirects !== '/login' &&
                    val.urlAfterRedirects !== '/register' &&
                    val.urlAfterRedirects !== '/account/confirmation'
                ) {
                    navigationHistory.unshift(val.urlAfterRedirects);
                }
                this.store.set('navigationHistory', navigationHistory);

                if (val.urlAfterRedirects.indexOf('/waox/') === 0) {
                    // auto auth route that will be handled here
                    const secretToken = val.urlAfterRedirects.split('/')[2];
                    const sessionId = this.store.selectForLocal('sessionId') ?? undefined;

                    if (secretToken) {
                        cmsService
                            .getUrlSettings(secretToken, sessionId)
                            .subscribe((data: { redirect: string; userId: string }) => {
                                this.authService.initUserAuth({
                                    sessionId,
                                    id: data.userId,
                                });
                                this.userService.getByToken().subscribe((userData) => {
                                    this.chatService.receiveChat();
                                    this.notificationService.listenToNotifications(userData.id);
                                    this.router.navigateByUrl(data.redirect);
                                });
                            });
                    }
                }
                // this.canonicalService.setCanonicalURL();
            }
        });

        this.epicFunction();

        let metaTagsConfig: MetaDefinition[] = [
            {
                name: 'robots',
                content: 'noindex',
            },
            {
                name: 'googlebot',
                content: 'noindex',
            },
        ];
        if (environment.production) {
            metaTagsConfig = [
                {
                    name: 'robots',
                    content: 'index',
                },
                {
                    name: 'googlebot',
                    content: 'index',
                },
            ];
        }

        meta.addTags(metaTagsConfig);
    }

    ngOnInit() {
        if (this.isBrowser) {
            this.checkCookie();
            this.getDeviceSize();
            this.store.set('innerWidth', window.innerWidth);

            this.sessionId = this.toolsService.sessionId();
            this.basketService.getBasketId()
            this.store.set('sessionId', this.sessionId);
            this.cookieService.put('sessionId', this.sessionId);

            setInterval(() => {
                this.chatService.sendOnlineSignal();
            }, 5000);

            const localFavourites = localStorage.getItem('favourites');
            if (localFavourites) {
                this.store.set('favourites', JSON.parse(localFavourites));
            }

            let authToken: boolean;
            try {
                authToken = JSON.parse(this.cookieService.get(IS_LOGGED_IN));
            } catch {
                // ...
            }
            if (authToken === true) {
                this.store.set('loggedIn', true);
            } else {
                this.store.set('user', undefined);
                this.store.set('token', '');
                this.store.set('loggedIn', false);
            }

            console.log(this.cookieService.get(USER_COOKIE))
            const ysUser: any = this.cookieService.get(USER_COOKIE);
            if (ysUser && ysUser !== 'undefined') {
                const x: any = JSON.parse(ysUser);
                this.userId = x.id;
                this.store.set('user', x);
                this.store.set('accountType', x.accountType);
                this.chatService.receiveChat();
                this.notificationService.listenToNotifications(x.id);
            } else {
                if (ysUser === 'undefined') {
                    this.cookieService.remove(USER_COOKIE);
                }
                this.store.set('user', undefined);
                this.store.set('loggedIn', false);
                this.store.set('accountType', '');
                this.chatService.receiveChat();
            }

            const ysBasket = localStorage.getItem('ysBasket');
            if (ysBasket) {
                this.store.set('basket', JSON.parse(ysBasket));
            }

            // 01/07/2021
            // this.cookieService.check();
            // this.cookieService.checkUser();

            // if (this.cookieService.checkUser()) {
            //     const basketId = this.basketService.getBasketId();
            //     this.basketService.getBasket(basketId).subscribe((data) => {
            //     });
            // }
            // this.cookieService.getBasket();
            this.userService.logSession(this.userId, this.sessionId).subscribe();

            // please leave in for dev.
            console.log(this.store);

            this.sessionService.trackClicks(undefined).subscribe(data => {
                if (data.data.publicTelNo) {
                    this.store.set('publicTelNo', data.data.publicTelNo);
                } else {
                    this.store.set('publicTelNo', '01483 930104');
                }

                if (data.data.commands && data.data.commands.length) {
                    this.executeSessionCommands(data.data.commands);
                }

            });

            this.startTrackingClicks();

        }

        // this.authService.authTimeout();
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // TODO set this live
                if (this.isBrowser) {
                    if (this.router.getCurrentNavigation().previousNavigation) {
                        gtag('config', 'UA-33766575-1', {
                            page_path: event.urlAfterRedirects,
                        });
                    }
                }

                const navigationHistory = this.store.selectForLocal('navigationHistory');

                if (
                    event.urlAfterRedirects !== '/login' &&
                    event.urlAfterRedirects !== '/register' &&
                    event.urlAfterRedirects !== '/account/confirmation' &&
                    event.urlAfterRedirects !== '/password/confirmation'
                ) {
                    navigationHistory.unshift(event.urlAfterRedirects);
                }

                this.store.set('navigationHistory', navigationHistory);
            }
        });
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.route),
                map((route) => {
                    while (route.firstChild) {
                        route = route.firstChild;
                        return route;
                    }
                }),
                filter((route) => route.outlet === 'primary'),
                mergeMap((route) => route.data)
            )
            .subscribe((event) => {
                if (event.protected) {
                    this.store.set('protectedRoute', event.protected);
                } else {
                    this.store.set('protectedRoute', false);
                }
            });

        // if (!this.store.selectForLocal('user')) {
        //     this.router.navigate(['account']);
        // }
    }

    startTrackingClicks() {

        this.clickSubscription = fromEvent(document, 'click').subscribe(e => {
            this.sessionService.trackClicks(e).subscribe(data => {
                if (data.data.publicTelNo) {
                    this.store.set('publicTelNo', data.data.publicTelNo);
                } else {
                    this.store.set('publicTelNo', '01483 930104');
                }

                if (data.data.commands && data.data.commands.length) {
                    this.executeSessionCommands(data.data.commands);
                }
            });
        });
    }

    executeSessionCommands(commands) {
        for (let i = 0; i < commands.length; i++) {
            if (commands[i] === 'emptyBasket') {
                this.basketService.renewBasket()
            }
            if (commands[i] === 'clearCache') {
                localStorage.clear();
                this.basketService.renewBasket()
                location.reload();
            }
            if (commands[i] === 'refreshBrowser') {
                location.reload();
            }
        }
    }

    checkCookie() {
        var cookieEnabled = navigator.cookieEnabled;
        if (!cookieEnabled) {
            document.cookie = 'testcookie';
            cookieEnabled = document.cookie.indexOf('testcookie') != -1;
        }
        return cookieEnabled || alert('Cookies must be enabled for site functionality. Please enable cookies');
    }

    epicFunction() {
        const device = {
            info: this.deviceService.getDeviceInfo(),
            isMobile: this.deviceService.isMobile(),
            isTablet: this.deviceService.isTablet(),
            isTabletV: this.deviceService.isTablet(),
            isTabletH: false,
            isDesktopDevice: this.deviceService.isDesktop(),
            mobileVersion: true,
        };
        if (this.deviceService.isDesktop()) {
            device.mobileVersion = false;
            this.store.set('deviceType', 'desktop');
        }
        const innerWidth = window.innerWidth;
        if (device.isTablet || device.isMobile) {
            if (innerWidth > 767) {
                device.mobileVersion = true;
                device.isMobile = false;
                device.isTablet = true;
                device.isTabletV = true;
                device.isTabletH = false;
            }
            if (innerWidth > 1020) {
                device.mobileVersion = false;
                device.isMobile = false;
                device.isTablet = true;
                device.isTabletV = false;
                device.isTabletH = true;
            }
        }

        this.store.set('device', device);
    }

    getDeviceSize() {
        let deviceType = this.store.selectForLocal('deviceType');

        // Todo add innerHeight for mobile landscape breakpoints detection
        if (window.innerWidth <= 576) {
            deviceType = 'mobile';
        }
        if (window.innerWidth > 576 && window.innerWidth <= 768) {
            deviceType = 'tabletV';
        }
        if (window.innerWidth > 768 && window.innerWidth <= 1200) {
            deviceType = 'tabletH';
        }
        if (window.innerWidth > 1200) {
            deviceType = 'desktop';
        }
        this.store.set('innerWidth', window.innerWidth);
        this.store.set('deviceType', deviceType);
    }

    closeAll() {
        this.overlayService.closeAll();
    }
}
