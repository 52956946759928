import { Inject, Injectable, isDevMode, Optional, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { FACEBOOK_PIXEL_SETTINGS_TOKEN } from '../token/facebook-pixel-settings-token';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

declare let fbq: any;

@Injectable({
    providedIn: 'root',
})
export class FacebookPixelService {
    private readonly doc: Document;
    private renderer: Renderer2;

    private destroy$ = new Subject();

    constructor(
        @Inject(FACEBOOK_PIXEL_SETTINGS_TOKEN) private readonly settings,
        @Inject(DOCUMENT) private readonly documentReference: any,
        @Inject(PLATFORM_ID) private platformId: {},
        @Optional() private router: Router,
        private rendererFactory: RendererFactory2
    ) {
        // DOCUMENT cannot be injected directly as Document type, see https://github.com/angular/angular/issues/20351
        // It is therefore injected as any and then cast to Document
        this.doc = documentReference as Document;
        this.renderer = rendererFactory.createRenderer(null, null);

        if (router) {
            // Log page views after router navigation ends
            router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    takeUntil(this.destroy$)
                )
                .subscribe((event) => {
                    if (this.isLoaded()) {
                        this.event('PageView');
                    }
                });
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    initialize(pixelId = this.settings.pixelId): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        if (this.isLoaded()) {
            return;
        }
        this.addPixelScript(pixelId);
    }

    addPixelScript(pixelId) {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        if (!pixelId) {
            if (isDevMode()) {
                console.error('Empty pixel id for Facebook Pixel. Make sure to provide one in the environment.');
            }

            return;
        }

        if (!this.doc) {
            if (isDevMode()) {
                console.error('Was not possible to access Document interface.');
            }

            return;
        }

        const pixelCode = `
            var pixelCode = function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${pixelId}');
            fbq('track', 'PageView');`;

        const scriptElement: HTMLScriptElement = this.doc.createElement('script');
        this.renderer.setAttribute(scriptElement, 'id', 'fb-pixel-script');
        scriptElement.async = true;
        scriptElement.innerHTML = pixelCode;
        const head: HTMLHeadElement = this.doc.getElementsByTagName('head')[0];
        head.appendChild(scriptElement);
    }

    removePixelScript(): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        const pixelElement = this.doc.getElementById('fb-pixel-script');
        if (pixelElement) {
            pixelElement.remove();
        }
    }

    private isLoaded(): boolean {
        if (isPlatformBrowser(this.platformId)) {
            const pixelElement = this.doc.getElementById('fb-pixel-script');
            return !!pixelElement;
        }
        return false;
    }

    event(eventName: string, properties?: {}): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        if (!this.settings.isEnabled) {
            return;
        }

        if (!fbq) {
            if (!isDevMode()) {
                console.warn('Tried to track an event without initializing a Pixel instance.');
            }
            return;
        }

        if (properties) {
            fbq('track', eventName, properties);
        } else {
            fbq('track', eventName);
        }
    }

    eventCustom(eventName: string, properties?: {}): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        if (!this.settings.isEnabled) {
            return;
        }

        if (!fbq) {
            if (!isDevMode()) {
                console.warn('Tried to track an event without initializing a Pixel instance.');
            }
            return;
        }

        if (properties) {
            fbq('trackCustom', eventName, properties);
        } else {
            fbq('trackCustom', eventName);
        }
    }

    eventPurchase(basket, contents) {
        this.event('Purchase', {
            value: basket.values.gross,
            currency: 'GBP',
            contents: contents,
            content_type: 'product',
        });
    }
}
