import { Injectable } from "@angular/core";
import { Store } from "../store";
import {
    AlertConfirmData,
    AlertData,
    CustomAlertData,
    DefaultAlertData,
    DefaultSimpleAlertData, SimpleAlertData
} from "../data-types/alert-data";
import { isString } from "ngx-cookie";

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private timeout = null;

    constructor(
        private store: Store,
    ) {
    }

    notification(notifications, timeout?, title?: string): Promise<any>
    {
        return new Promise<any>((resolve, reject) => {
            this.store.set('alerts', {
                ...this.resetAlert(),
                notifications,
                title,
                closable: notifications.closable ?? false,
                resolve,
                reject
            });

            clearTimeout(this.timeout);
            if (timeout) {
                this.timeout = setTimeout(() => {
                    this.clearMessage();
                }, timeout);
            }
        });
    }

    error(errors): Promise<any>
    {
        return new Promise<any>((resolve, reject) => {
            this.store.set('alerts', {
                ...this.resetAlert(),
                errors,
                closable: errors.closable ?? false,
                resolve,
                reject
            });
        });
    }

    confirm(confirmAction: AlertConfirmData): Promise<any>
    {
        return new Promise<any>((resolve, reject) => {
            this.store.set('alerts', {
                ...this.resetAlert(),
                confirmAction,
                closable: confirmAction.closable ?? false,
                resolve,
                reject
            });
        });
    }

    customAlert(customAlert: CustomAlertData): Promise<any>
    {
        return new Promise<any>((resolve, reject) => {
            this.store.set('alerts', {
                ...this.resetAlert(),
                customAlert,
                closable: customAlert.closable ?? false,
                resolve,
                reject
            });
        });
    }

    async simple(simple: string | SimpleAlertData): Promise<any>
    {
        return new Promise<any>((resolve, reject) => {
            if (isString(simple)) {
                simple = {
                    ...DefaultSimpleAlertData,
                    message: simple
                };
            }
            this.store.set('alerts', {
                ...this.resetAlert(),
                simple,
                closable: simple.closable ?? false,
                resolve,
                reject
            });
        });
    }

    clearMessage(): void
    {
        this.store.set('alerts', this.resetAlert());
    }

    private resetAlert(): AlertData
    {
        const g = this.store.selectForLocal('alerts');
        return { ...g, ...DefaultAlertData}  as AlertData;
    }
}
