import { Injectable } from '@angular/core';
import { finalize, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApiAuthHttpClient } from '../http/ApiAuthHttpClient';
import { Store } from '../store';
import { BasketItem, PricingPayload, PricingPayloadExtras, PricingResult } from '../data-types/basket.data';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { User } from '../data-types/user-data';

@Injectable({
    providedIn: 'root',
})
export class ProductService {
    env = environment;

    private pricingBusy$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    // emits true when pricing is busy and false when it's done
    readonly pricingBusy: Observable<boolean> = this.pricingBusy$.asObservable(); //

    constructor(private http: ApiAuthHttpClient, private store: Store) { }

    priceProductSkuSingle(itemToPrice) {
        const dataToSend = {
            itemsToPrice: [itemToPrice],
        };
        return defer(() => {
            this.pricingBusy$.next(true);
            return this.http
                .post(this.env.apiPath + 'pricing/sku', dataToSend)
                .pipe(map(({ data }: any) => data))
                .pipe(finalize(() => this.pricingBusy$.next(false)));
        });
    }

    generateBespokePricingResults(itemToPrice: PricingPayload, skuId?: string): Observable<Array<PricingResult>> {
        const dataToSend = {
            itemToPrice,
            skuId,
        };
        return defer(() => {
            this.pricingBusy$.next(true);
            return this.http.post<Array<PricingResult>>(this.env.apiPath + 'pricing/bespoke', dataToSend).pipe(
                map(({ data }: any) => data),
                finalize(() => this.pricingBusy$.next(false))
            );
        });
    }

    regenerateBespokePricingResults(basketItem: BasketItem): Observable<PricingResult> {
        let extras: PricingPayloadExtras = {};

        if (basketItem.product.cornersLR) {
            extras.cornersLR = { quantity: basketItem.product.cornersLR };
        }

        if (basketItem.product.cornersRR) {
            extras.cornersRR = { quantity: basketItem.product.cornersRR };
        }

        if (basketItem.product.ends) {
            extras.ends = { quantity: basketItem.product.ends };
        }

        if (basketItem.product.riserRequired) {
            extras.riser = {
                height: basketItem.product.riser.height,
                quantity: basketItem.product.quantity,
                thickness: basketItem.product.riser.thickness,
            };
        }

        const itemToPrice: PricingPayload = {
            isBespoke: true,
            applyDiscount: true,
            quantity: basketItem.qty,
            dimensions: {
                l: {
                    trueSize: basketItem.product.length,
                    size: +basketItem.product.length / 1000,
                    skuId: '',
                },
                w: {
                    trueSize: basketItem.product.width,
                    size: +basketItem.product.width / 1000,
                    skuId: '',
                },
                t: {
                    trueSize: basketItem.product.height,
                    size: +basketItem.product.height / 1000,
                    skuId: '',
                },
            },
            supplierId: '',
            rangeId: '',
            productType: basketItem.product.typeId,
            finishId: basketItem.product.finishId,
            pricingMethod: '',
            sellUnit: basketItem.sellUnit.id,
            margin: 0,
            discount: 0,
            finishes: {
                top: basketItem.product.finishTop ? { skuId: basketItem.product.finishTop.skuId } : undefined,
                bottom: basketItem.product.finishBottom ? { skuId: basketItem.product.finishBottom.skuId } : undefined,
                masonry: basketItem.product.finishEdge ? { skuId: basketItem.product.finishEdge.skuId } : undefined,
                weathering: basketItem.product.weathering ? { skuId: basketItem.product.weathering.skuId } : undefined,
            },
            widthRandom: basketItem.product.widthRandom,
            lengthRandom: basketItem.product.lengthRandom,
            extras,
        };

        const user = this.store.selectForLocal<User>('user');
        if (user) {
            itemToPrice.customerId = user.id;
        }

        return this.generateBespokePricingResults(itemToPrice, basketItem.skuId).pipe(map((items) => items[0]));
    }

    productPricingBespoke(itemToPrice) {
        const dataToSend = {
            itemsToPrice: [itemToPrice],
        };

        return this.http.post(this.env.apiPath + 'pricing/sku', dataToSend).pipe(map((data: any) => data));
    }

    findAll() {
        return this.http.get(this.env.apiPath + 'products/all').pipe(map((data: any) => data));
    }

    getLinkedProducts(productId) {
        return this.http.get(this.env.apiPath + 'products/linked/' + productId).pipe(map((data: any) => data));
    }
    findJoblotAlternateLeadtime(productId, skuId, quantity) {
        return this.http.get(`${this.env.apiPath}products/public/${productId}/${skuId}/leadtime/${quantity}`).pipe(map((data: any) => data));
    }
    findJoblotStockLevel(productId, skuId) {
        return this.http.get(`${this.env.apiPath}products/public/${productId}/${skuId}/stockcheck`).pipe(map((data: any) => data));
    }
    getLinkedCaseStudies(productId) {
        return this.http.get(this.env.apiPath + 'products/case-studies/' + productId).pipe(map((data: any) => data));
    }

    getProductDetails(slug) {
        return this.http.get(this.env.apiPath + 'products/public/' + slug).pipe(map((data: any) => data));
    }

    getProductGroups() {
        return this.http.get(this.env.apiPath + 'product-groups').pipe(map((data: any) => data));
    }

    getProductGroup(slug) {
        return this.http.get(this.env.apiPath + 'product-groups/' + slug).pipe(map((data: any) => data));
    }

    productsByGroup(productGroup) {
        return this.http.get(this.env.apiPath + 'products/group/' + productGroup.id).pipe(map((data: any) => data));
    }

    productsByGroups(productGroups: Array<number>) {
        return this.http
            .get(this.env.apiPath + 'products/groups?groupId[]=' + productGroups.join('&groupId[]='))
            .pipe(map((data: any) => data));
    }

    productsDetailsByGroups(productGroups: Array<number>) {
        return this.http
            .get(this.env.apiPath + 'products/groups/details?groupId[]=' + productGroups.join('&groupId[]='))
            .pipe(map((data: any) => data));
    }

    getProductRange(slug) {
        return this.http.get(this.env.apiPath + 'product-range/public/' + slug).pipe(map((data: any) => data));
    }

    productsByRange(productRange) {
        return this.http.get(this.env.apiPath + 'products/range/' + productRange.id).pipe(map((data: any) => data));
    }

    specialOffers() {
        return this.http.get(this.env.apiPath + 'products/offers/').pipe(map((data: any) => data));
    }

    search(params) {
        const dataToSend = {
            params,
        };
        return this.http.post(this.env.apiPath + 'products/search', dataToSend).pipe(map((data: any) => data));
    }

    marketplace(params) {
        const dataToSend = {
            params,
        };
        return this.http.post(this.env.apiPath + 'products/marketplace', dataToSend).pipe(map((data: any) => data));
    }

    getProductRanges() {
        return this.http.get(this.env.apiPath + 'product-range/public/all').pipe(map((data: any) => data));
    }
}
