import { InjectionToken, inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

export function getWindow(platformId: any) {
    return (isPlatformBrowser(platformId))
        ? window : null;
}

export const WINDOW = new InjectionToken<Window>('window', {
    providedIn: 'root',
    factory: () => getWindow(inject(PLATFORM_ID)),
});
