import { Inject, Injectable, Injector, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Store } from "../store";
import { ToolsService } from "./tools.service";
import { environment as env } from "../../environments/environment";
import { map, takeUntil, tap } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { isPlatformBrowser } from '@angular/common';
import { Socket } from 'ngx-socket-io';
import { merge, ReplaySubject, Subject } from 'rxjs';
import { ApiAuthHttpClient } from '../http/ApiAuthHttpClient';

@Injectable({
    providedIn: 'root'
})
export class NotificationService implements OnDestroy
{
    query: any;
    private sound;
    private socket: Socket;
    private secretSocket: Socket;
    private publicSocket: Socket;
    private notification$: ReplaySubject<any>;
    private destroy$ = new Subject();

    constructor(
        private http: ApiAuthHttpClient,
        private store: Store,
        private toolsService: ToolsService,
        private toastrService: ToastrService,
        @Inject(PLATFORM_ID) private platformId: {},
        private injector: Injector,
    )
    {
        if (isPlatformBrowser(this.platformId)) {
            this.initSound();
            this.socket = this.injector.get<Socket>(Socket);
        }
    }

    init()
    {
        if (!this.notification$) { // caching this since it's used several times.
            this.notification$ = new ReplaySubject<any>();
            this.notification$.pipe(takeUntil(this.destroy$));
            const user = this.store.selectForLocal('user');
            this.http
                .get(env.apiPath + 'notifications/' + user.id)
                .pipe(
                    tap((data: any) => this.store.set('notifications', data.data)),
                    map((data: any) => data)
                ).subscribe(resuls => this.notification$.next(resuls));
        }

        return this.notification$;
    }

    ngOnDestroy(): void
    {
        this.destroy$.next();
        this.destroy$.complete();
    }

    listenToNotifications(secretNamespace: string)
    {
        this.secretSocket = new Socket({ url: `${env.socketPath}sc-${secretNamespace}` });
        this.publicSocket = new Socket({ url: `${env.socketPath}public` });

        merge(
            this.publicSocket.fromEvent('notifications'),
            this.secretSocket.fromEvent('notifications')
        ).pipe(takeUntil(this.destroy$)).subscribe((data: any) =>
        {
            const notifications = this.store.selectForLocal("notifications");
            notifications.unread++;
            notifications.data.unshift(data);
            this.sound.play();
            this.store.set("notifications", notifications);
            this.toastrService.success(data.content);
        });
    }

    stopListening()
    {
        this.store.set('notifications', { unread: 0, data: [] });
        this.publicSocket?.disconnect();
        this.secretSocket?.disconnect();
    }

    send(notificationData)
    {
        notificationData.id = this.toolsService.newUUID();
        notificationData.read = false;
        notificationData.deleted = false;
        notificationData.createdAt = new Date();
    }

    private initSound()
    {
        this.sound = document.createElement("audio");
        this.sound.src = "/assets/ding.mp3";
        this.sound.setAttribute("preload", "auto");
        this.sound.setAttribute("controls", "none");
        this.sound.style.display = "none";
        this.sound.volume = 0.2;
        document.body.appendChild(this.sound);
    }

    delete(id)
    {
        return this.http
            .delete(env.apiPath + "notifications/" + id);
    }

    markAllAsRead(id)
    {
        return this.http
            .put(env.apiPath + 'notifications/user/' + id, {})
            .pipe(map((data: any) => data));
    }
}
