import { Injectable } from '@angular/core';
import { Store } from '../store';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';
import { ApiAuthHttpClient } from '../http/ApiAuthHttpClient';

@Injectable({
    providedIn: 'root'
})
export class CmsService {
    constructor(
        private http: ApiAuthHttpClient,
        private apiService: ApiService,
        private store: Store,
    ) {
    }

    search(searchText) {
        const dataToSend = {
            searchText
        };
        return this.http.post(env.apiPath + 'cms/search', dataToSend).pipe(map((data: any) => {
            this.store.set('pages', data.data);
            return data.data;
        }));
    }

    pages() {
        return this.http.get(env.apiPath + 'cms/pages').pipe(map((data: any) => {
            this.store.set('pages', data.data);
            return data.data;
        }));
    }

    page(route) {
        const routeToFind = route.split('?')[0];
        return this.http.get(env.apiPath + 'cms/page/' + routeToFind).pipe(map((data: any) => {
            return data.data;
        }));
    }

    pageSnippets(route) {
        const routeToFind = route.split('?')[0];
        return this.http.get(env.apiPath + 'cms/page/snippets/' + routeToFind).pipe(map((data: any) => {
            this.store.set('pageSnippets', data.data);
            return data.data;
        }));
    }

    resetPageSnippets() {
        this.store.set('pageSnippets', []);
    }

    quickLinks(type) {
        return this.http
            .get(env.apiPath + 'pages/quicklinks/' + type)
            .pipe(map((data: any) => data.data));
    }

    sendContactForm(contact) {
        const dataToSend = {
            contact
        };
        return this.http
            .post(env.apiPath + 'contactus', dataToSend)
            .pipe(map((data: any) => data.data));
    }

    sendCompetitionForm(contact) {
        const dataToSend = {
            contact
        };
        return this.http
            .post(env.apiPath + 'contactus/competition', dataToSend)
            .pipe(map((data: any) => data.data));
    }

    sendSubscriberForm(subscriber) {
        const dataToSend = {
            subscriber
        };
        return this.http
            .post(env.apiPath + 'subscriber', dataToSend)
            .pipe(map((data: any) => data));
    }

    getSettings() {
        return this.http.get(env.apiPath + 'settings')
            .pipe(map((data: any) => {
                this.store.set('settings', data.data);
                return data.data;
            }));
    }

    getUrlSettings(token: string, sessionId?: string) {
        return this.http
            .get(env.apiPath + 'settings/url/' + token + (sessionId ? '/' + sessionId : ''))
            .pipe(map((data: any) => data.data));
    }

    gallery(id) {
        return this.http
            .get(env.apiPath + 'cms/gallery/' + id)
            .pipe(map((data: any) => data.data));
    }

    getStaff(slug) {
        return this.http
            .get(env.apiPath + 'staff/slug/' + slug)
            .pipe(map((data: any) => data.data));
    }

    getTermsContent() {
        return this.http
            .get(env.apiPath + 'terms')
            .pipe(map((data: any) => data.data));
    }
}
