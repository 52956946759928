<section [ngClass]="backgroundColour" class="contact-cta position-relative">
    <div class="contact-cta__content d-flex flex-column justify-content-center align-items-center position-relative h-100">
        <div class="ff-spectral fs-3 fw-500 text-center text-white mb-3">Contact us</div>
        <ng-container *ngIf="(settings$ | async)">
            <div class="ff-spectral fs-3 fw-500 text-white text-center mb-3">
                <span>Call </span>
                <a class="text-white text-decoration-none"
                   gaEvent="click_phone_number" gaCategory="Contact" gaBind="click"
                   fbqEvent="click_phone_number" fbqBind="click"
                   href="tel:{{(publicTelNo$ | async)}}">{{(publicTelNo$ | async) | strReplace: '+44': '0'}}</a>
                <span> or email </span>
                <br class="d-block d-xxl-none">
                <a class="email text-white text-decoration-none" target="_blank"
                   gaEvent="click_email_address" gaCategory="Contact" gaBind="click"
                   fbqEvent="click_email_address" fbqBind="click"
                   [href]="'mailto:' +  (settings$ | async).emailOne">{{(settings$ | async).emailOne}}</a></div>
        </ng-container>
        <div class="text-center">
            <a type="button"
               class="btn btn-light fw-bold btn-wide"
               [href]="'/contact-us'"
               [routerLink]="['/contact-us']">
                Contact us
            </a>
        </div>
    </div>
    <img class="image-as-bg" [src]="'https://ys-assets.b-cdn.net/cms/images/assets/yorkstone-mak-blue.jpg'"
         [appScrset]="'https://ys-assets.b-cdn.net/cms/images/assets/yorkstone-mak-blue.jpg'" [width]="1920">
</section>
