import { Component, Input, NgModule, OnInit } from '@angular/core';
import { Store } from '../../store';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '../../directives/directives.module';
import { GoogleAnalyticsModule } from '../../modules/tracking/google-analytics.module';
import { FacebookPixelModule } from '../../modules/tracking/facebook-pixel.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

@Component({
    selector: 'app-contact-cta',
    templateUrl: './contact-cta.component.html',
    styleUrls: ['./contact-cta.component.scss']
})

export class ContactCtaComponent implements OnInit {
    @Input() backgroundColour = 'bg-grey';
    settings$ = this.store.select<any>('settings');
    deviceType$ = this.store.select<string>('deviceType');
    publicTelNo$ = this.store.select<string>('publicTelNo');

    constructor(
        private store: Store
    ) {
    }

    ngOnInit(): void {
    }
}

@NgModule({
    declarations: [ContactCtaComponent],
    imports: [
        CommonModule,
        RouterModule,
        DirectivesModule,
        GoogleAnalyticsModule,
        FacebookPixelModule,
        PipesModule,
    ],
    exports: [
        ContactCtaComponent
    ]
})

export class ContactCtaModule { }
