import {Inject, ModuleWithProviders, NgModule, PLATFORM_ID} from '@angular/core';
import {CommonModule, isPlatformBrowser} from '@angular/common';
import {GOOGLE_ANALYTICS_SETTINGS_TOKEN} from './token/google-analytics-settings-token';
import {GaEventDirectiveDirective} from './directives/ga-event-directive.directive';
import {GaEventCategoryDirective} from './directives/ga-event-category.directive';
import {GoogleAnalyticsService} from './services/google-analytics.service';

@NgModule({
    declarations: [
        GaEventDirectiveDirective,
        GaEventCategoryDirective
    ],
    exports: [
        GaEventDirectiveDirective,
        GaEventCategoryDirective
    ],
    imports: [
        CommonModule
    ]
})
export class GoogleAnalyticsModule {
    constructor(
        private gaService: GoogleAnalyticsService,
        @Inject(GOOGLE_ANALYTICS_SETTINGS_TOKEN) private readonly settings,
    ) {
    }

    static forRoot(trackingCode: string, adsCode: string, phoneTrackingId: string, phoneTrackingNumber: string, initCommands?: [], isEnabled?: boolean): ModuleWithProviders<GoogleAnalyticsModule> {
        return {
            ngModule: GoogleAnalyticsModule,
            providers: [
                {
                    provide: GOOGLE_ANALYTICS_SETTINGS_TOKEN,
                    useValue: {
                        trackingCode,
                        adsCode,
                        phoneTrackingId,
                        phoneTrackingNumber,
                        initCommands,
                        isEnabled
                    }
                },
            ]
        };
    }
}
