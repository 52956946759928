import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Store } from '../store';
import { CookieService } from 'ngx-cookie';
import { filter, map, tap } from 'rxjs/operators';
import { UserService } from './user.service';
import { environment as env } from '../../environments/environment';
import { BasketService } from './basket.service';
import { ToolsService } from './tools.service';
import { FavouritesService } from './favourites.service';
import { ApiAuthHttpClient } from '../http/ApiAuthHttpClient';
import { USER_COOKIE, IS_LOGGED_IN } from '../shared/constants';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    authInterval;

    constructor(
        private apiService: ApiService,
        private apiAuthHttp: ApiAuthHttpClient, // errors, single and authentication request handled
        private store: Store,
        private cookieService: CookieService,
        private userService: UserService,
        private basketService: BasketService,
        private toolsService: ToolsService,
        private favouritesService: FavouritesService
    ) {
        this.toolsService.toolsEvents$$
            .pipe(filter((item) => item === 'clearUserStorages'))
            .subscribe(() => this.clearUserStore().clearUserCookies());
    }

    getTmpSessionId() {
        const sessionId = localStorage.getItem('tmpSessionId');
        if (!sessionId) {
            const newSessionId = this.toolsService.newUUID();
            localStorage.setItem('tmpSessionId', this.toolsService.newUUID());
            return newSessionId;
        } else {
            return sessionId;
        }
    }

    login(user) {
        const sessionId = this.store.selectForLocal('sessionId');

        if (sessionId) {
            user.sessionId = sessionId;
        }

        return this.apiAuthHttp.post(env.apiPath + 'users/login', user).pipe(
            map(async (data: any) => {
                this.initUserAuth(user);
                return data;
            })
        );
    }

    initUserAuth(user) {
        this.store.set('loggedIn', true);
        this.cookieService.put(USER_COOKIE, JSON.stringify(user));
        this.cookieService.put(IS_LOGGED_IN, JSON.stringify(true));
        this.toolsService.setSessionId(user.id);
        localStorage.setItem('user', user.id);

        // sync favourites
        this.favouritesService.saveRemote();

        this.basketService.setGuest(false);
    }

    logout() {
        return this.apiAuthHttp.post(env.apiPath + 'users/logout', {}).pipe(tap(() => this.cleanUserData()));
    }

    cleanUserData() {
        this.basketService.clearUserFromBasket();
        this.toolsService.logoutSessionId();
        this.clearUserStore().clearUserCookies();
        return this;
    }

    clearUserStore() {
        this.store.set('user', undefined);
        this.store.set('guestUser', undefined);
        this.store.set('token', undefined);
        return this;
    }

    clearUserCookies() {
        this.cookieService.remove(IS_LOGGED_IN);
        this.cookieService.remove(USER_COOKIE);
        return this;
    }

    passwordReset(email) {
        const dataToSend = {
            email,
        };
        return this.apiAuthHttp.post(env.apiPath + 'users/password/reset', dataToSend).pipe(map((data: any) => data));
    }

    passwordSet(resetData) {
        return this.apiAuthHttp
            .post(env.apiPath + 'users/password/set', resetData)
            .pipe(map(async (data: any) => data));
    }

    authTimeout() {
        /*        this.authInterval = setInterval(() => {
                    let ttl = this.store.selectForLocal('ttl');
                    ttl--;
                    this.store.set('ttl', ttl);
                    if (ttl === 0) {
                        this.logout();
                    }
                }, 50000);*/
    }

    resetAuthTimeout() {
        this.store.set('ttl', 1000); // reset to 10
    }

    clearAuthTimeout() {
        clearInterval(this.authInterval);
    }
}
