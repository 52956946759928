<div class="trustpilot position-relative"
     [ngClass]="showDots ? 'px-3 pt-3 pb-3 px-sm-4 pt-sm-4 px-md-5 pt-md-5' : 'p-3 p-sm-4 p-md-5'"
     [ngStyle]="{'background-color': backgroundColour}"
     [appScrset]="backgroundImage">
    <img *ngIf="backgroundImage" class="image-as-bg" [src]="backgroundImage" [appScrset]="backgroundImage" [width]="1920">
    <div class="keen-slider overflow-visible">
        <div
            class="keen-slider position-relative" #sliderRef>
            <div
                class="keen-slider__slide text-center"
                [ngClass]="{'active' : gi === currentSlide}"
                *ngFor="let pg of tiles; let gi = index"
                (click)="currentSlide = gi">
                <div class="slide h-100">
                    <div class="d-flex flex-column align-items-center h-100 py-5 px-4 px-md-5">
                        <a class="trustpilot-slider-image align-self-start mb-3" href="https://uk.trustpilot.com/review/yorkstonesupplies.co.uk"
                           target="_blank" rel="noreferrer">
                            <svg-icon src="assets/images/icon-trustpilot-text-black.svg" [svgClass]="'trustpilot-slider-icon'"></svg-icon>
                        </a>
                        <div class="fs-5 ff-spectral fw-bold text-primary mb-auto mb-sm-3 mb-xl-3">"{{pg.header}}"</div>
                        <div class="slide__review fs-6" [innerHTML]="pg.review"></div>
                        <a
                            class="btn btn-trustpilot mt-auto"
                            href="https://uk.trustpilot.com/review/yorkstonesupplies.co.uk"
                            target="_blank"
                            rel="noreferrer">
                            Visit Trustpilot <i class="fa fa-external-link-alt btn-trustpilot__icon"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="(deviceType$| async) === 'desktop'">
        <div (click)="prevSlide($event)"
             (mouseenter)="arrowPreviousState(true)"
             (mouseleave)="arrowPreviousState(false)"
             [ngClass]="'arrow--trust arrow--left ' + ( currentSlide === 0 ? 'arrow--disabled' : '')">
            <svg-icon
                [src]="arrowLeftSrc"
                [style.stroke]="arrowColour"
                [style.fill]="arrowColour">
            </svg-icon>
        </div>
        <div (click)="nextSlide($event)"
             (mouseenter)="arrowNextState(true)"
             (mouseleave)="arrowNextState(false)"
             [ngClass]="'arrow--trust arrow--right ' + ((currentSlide + slidesPerView) === tiles.length ? 'arrow--disabled' : '')">
            <svg-icon
                [src]="arrowRightSrc"
                [style.stroke]="arrowColour"
                [style.fill]="arrowColour">
            </svg-icon>
        </div>
    </ng-container>
    <div *ngIf="(deviceType$| async) !== 'desktop'" class="position-relative d-flex justify-content-center pt-3">
        <button
            (click)="slider.moveToSlideRelative(i)"
            *ngFor="let slide of dotHelper; let i = index"
            [class]="'dot ' + (i === currentSlide ? 'active' : '')"
        ></button>
    </div>
</div>

