import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionnaireRespondComponent } from './questionnaire-respond/questionnaire-respond.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { QuestionnaireCompleteComponent } from './questionnaire-complete/questionnaire-complete.component';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
    declarations: [QuestionnaireRespondComponent, QuestionnaireCompleteComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        NgSelectModule,
    ],
})
export class QuestionnaireModule
{
}
