import {inject, InjectionToken} from '@angular/core';
import {DATA_LAYER} from './data-layer-token';
import {WINDOW} from './window-token';

export function getGtagFn(window: Window, dataLayer) {
    return window ? window['gtag'] = window['gtag'] || function() {
            dataLayer.push(arguments as any);
        }
        : null;
}

export const GTAG_FN = new InjectionToken('gtag-fn', {
    providedIn: 'root',
    factory: () => getGtagFn(inject(WINDOW), inject(DATA_LAYER))
});
