import {Directive, ElementRef, Inject, Input, OnDestroy, isDevMode, Optional} from '@angular/core';
import {GOOGLE_ANALYTICS_SETTINGS_TOKEN} from '../token/google-analytics-settings-token';
import {GoogleAnalyticsService} from '../services/google-analytics.service';
import {fromEvent, Subscription} from 'rxjs';
import {GaActionEnum} from '../enums/ga-action.enum';
import {GaEventCategoryDirective} from './ga-event-category.directive';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[gaEvent]',
    exportAs: 'gaEvent'
})
export class GaEventDirectiveDirective implements OnDestroy {

    constructor(
        @Optional() private gaCategoryDirective: GaEventCategoryDirective,
        @Inject(GOOGLE_ANALYTICS_SETTINGS_TOKEN) private settings,
        private gaService: GoogleAnalyticsService,
        private readonly el: ElementRef
    ) {
    }

    private bindSubscription?: Subscription;

    @Input() gaAction: GaActionEnum | string;
    @Input() gaLabel: string;
    @Input() label: string;
    @Input() gaValue: number;
    @Input() gaInteraction: boolean;
    @Input() gaEvent: GaActionEnum | string;

    private _gaBind: string;

    @Input() set gaBind(gaBind: string) {
        if (this.bindSubscription) {
            this.bindSubscription.unsubscribe();
        }

        this._gaBind = gaBind;
        this.bindSubscription = fromEvent(this.el.nativeElement, gaBind).subscribe(() => this.trigger());
    }

    get gaBind(): string {
        return this._gaBind;
    }

    ngOnDestroy() {
        if (this.bindSubscription) {
            this.bindSubscription.unsubscribe();
        }
    }

    protected trigger() {
        try {

            if (!this.gaAction && !this.gaEvent) {
                if (isDevMode()) {
                    console.error('You must provide a gaAction attribute to identify this event.');
                }
            }

            this.gaService
                .event(
                    this.gaAction || this.gaEvent,
                    (this.gaCategoryDirective) ? this.gaCategoryDirective.gaCategory : undefined,
                    this.gaLabel || this.label,
                );
        } catch (error) {
            if (isDevMode()) {
                console.error(error);
            }
        }
    }

}
