import { User, UserData } from './user-data';

export enum DiscountType {
    Pound = 2,
    Percent = 1,
}

export type SkuPriceTotals = { // in general final prices for the item/basket
    discount: number; // total discount
    net: number; //
    vat: number; // 0
    gross: number; // 0
    qty: number; // 0
    rewardDiscount: number; // 0,
    productDiscount: number; // 0,
    voucherDiscount: number; // 0
    preDiscount: PreDiscount;
};

export type PreDiscount = {
    net: number; // 0,
    vat: number; // 0,
    gross: number; // 0,
}

export type SkuPrice = { // pricing per peace
    totals: SkuPriceTotals; // totals are prices that should
    basePrice: number; // base price used to calculate other prices
    net: number; // price after discount is applied
    vat: number;
    gross: number;
    preDiscount: {
        net: number;
        vat: number;
        gross: number;
    };
    discount: DiscountValue;
    discountToUse: AvailableDiscounts;
    algoObj?: any; // temp variables
    algo?: any; // temp variables
};

export type AvailableDiscounts = 'none' | 'reward' | 'product' | 'voucher' | 'admin';

export type DiscountValue = {
    preDiscountValue: number;
    reward: RewardOrProduct;
    product: RewardOrProduct;
};

export type RewardOrProduct = {
    percentage: number;
    value: number;
}

export type BasketAddress = {
    // it might be the same as address but for now on it's own
    id: string;
    userId: string;
    add1: string;
    add2: string;
    add3: string;
    town: string;
    county: string;
    country: string;
    postcode: string;
    isBilling: boolean;
    defaultDeliveryAddress: boolean;
};

export const defaultBasketAddress: BasketAddress = {
    id: '',
    userId: '',
    add1: '',
    add2: '',
    add3: '',
    town: '',
    county: '',
    country: '',
    postcode: '',
    isBilling: false,
    defaultDeliveryAddress: false,
} as BasketAddress;

export type BasketProduct = BespokeBuildProduct & {
    id: string; // '',
    typeId: ProductTypeIds;
    name: string; // '',
    slug: string; // '',
    productCode: string; // ''
    image: string; // ''
    leadtimes: any[];
    masonry: any;
    jobLot: number;
    allowBreakage: number;
    isBespoke?: boolean;
    supplier?: Supplier;
    rangeId?: number;
    finishId?: any;
    range?: ProductRange;
};

export type BasketItem = {
    pricing: SkuPrice; // on item price with or without discount
    minQty: number;
    maxQty: number;
    maxQtyExceeded: boolean;
    qty: number;
    skuId: string;
    stockCheckResult: any;
    itemAvailable: boolean;
    lowStockWarning: boolean;
    lowStockLevel: number;
    sellUnit:
    | {
        name: string;
        id: string;
    }
    | undefined;
    product: BasketProduct;
    specificDateRequested: any;
    quantityMetres: number;
    sample: boolean;
    piecesRequired: number;
    singlePieceSize: number;
    unitPrice: number;
    unitPriceDiscount: number;
    unitPriceDiscountPerc: number;
    unitPriceGross: number;
    unitVatPrice: number;
    totalPrice: number;
    totalDiscount: number;
    totalPricePreDiscount: number;
    productDetailId: number; // 0,
    pricingMethod: {
        id: any;
    };
    weight: number;
    leadtime: number;
    separateShipment: boolean;
    potentialQty: number;
};

export type BasketValues = {
    discount: number;
    gross: number;
    net: number;
    vat: number;
    delivery: number;
    deliveryVat: number;
    // netPreDiscount: number;
    productDiscount: number;
    rewardDiscount: number;
    voucherDiscount: number;
    adminDiscount: number;
    voucher?: number;
    preDiscount: {
        net: number;
        vat: number;
        gross: number;
    };
};

export const defaultSkuPriceTotals: () => SkuPriceTotals = () => {
    return {
        // netPreDiscount: 0,
        discount: 0,
        net: 0,
        vat: 0,
        gross: 0,
        qty: 0,
        rewardDiscount: 0,
        productDiscount: 0,
        voucherDiscount: 0,
        preDiscount: {
            net: 0,
            vat: 0,
            gross: 0,
        }
    } as SkuPriceTotals;
}

export const defaultDiscountValue: () => DiscountValue = () => {
    return {
        preDiscountValue: 0,
        reward: {
            percentage: 0,
            value: 0,
        },
        product: {
            percentage: 0,
            value: 0,
        }
    } as DiscountValue;
};

export const defaultSkuPrice: () => SkuPrice = () => {
    return {
        totals: defaultSkuPriceTotals(),
        basePrice: 0,
        net: 0, // price after discount is applied
        vat: 0,
        gross: 0,
        preDiscount: {
            net: 0,
            vat: 0,
            gross: 0,
        },
        discount: defaultDiscountValue(),
        discountToUse: 'none',
    }
};

export const defaultBasketItem: () => BasketItem = () => {
    return {
        product: { id: '', productCode: '', name: '', slug: '', image: '', jobLot: 0, allowBreakage: 0 },
        sellUnit: undefined,
        quantityMetres: 0,
        skuId: '',
        sample: false,
        qty: 0,
        stockCheckResult: undefined,
        itemAvailable: true,
        lowStockWarning: false,
        lowStockLevel: 0,
        piecesRequired: 0,
        singlePieceSize: 0,
        totalPrice: 0,
        unitPrice: 0,
        unitPriceDiscount: 0,
        unitPriceGross: 0,
        totalDiscount: 0,
        totalPricePreDiscount: 0,
        pricing: defaultSkuPrice(),
        productDetailId: 0,
        weight: 0,
        leadtime: 0,
        minQty: 0,
    } as BasketItem
};

export type Basket = {
    source: 'public' | 'admin' | 'unknown'; // 'public'
    type: 'order' | 'quote'; // 'public'
    lastStep: 'entry' | 'checkout'; // 'public'
    customerType: 'trade' | 'customer' | 'new' | 'existing'; // 'public'
    items: Array<BasketItem>; // []
    itemsOriginal?: Array<BasketItem>;
    samples: Array<BasketItem>; // []
    potentialQty: number; // 0
    rewardsDiscountPercentage: number; // 0
    voucher: {
        code: string;
        discountType: DiscountType;
        discountValue: number;
        name: string;
        isExpired: string;

        allowOtherDiscounts?: number,
        createdAt?: string
        createdBy?: number
        deleted?: number,
        endDate?: string
        expiryDate?: string
        id?: number
        startDate?: string,
        status?: number
    } | undefined;
    voucherCodeEntered: string; // ''
    deliveryInstructions: string; // '';
    deliveryPostcode: string; // '';
    qualifiedForFreeDelivery?: any,
    deliveryOptions: {
        premium: any,
        economy: any,
        allowPremium: any,
        geo: {
            travelKM: number,
            travelMiles: number,
            travelMinutes: number,
            lat: number,
            lng: number,
        }
    },
    deliveryRestrictions: Array<string>,
    customDelivery: {
        value: any,
        active: boolean
    },
    differentDeliveryAddress: boolean; // false;
    selectedService: any,
    token: string | undefined; // undefined;
    customer?: UserData & {
        exists: boolean;
        [key: string]: any;
    }; // = undefined;
    deliveryAddress: BasketAddress | undefined; // = undefined;
    billingAddress: BasketAddress | undefined; // undefined;
    createdBy: UserData | undefined; // undefined;
    sampleOnly: boolean; // false;
    guest: boolean; // true;
    intent?: any;
    paymentMethod: 'CC' | 'BACS' | 'XE' | 'PP';
    shipments: any[];
    discount: { type: 'pound' | 'perc'; value: number }; // { type: 'pound', value: 0,  };
    restrictions: {
        slope: boolean;
        gravel: boolean;
        boards: boolean;
        offload: boolean;
    }; // { slope: false, gravel: false, boards: false, offload: false, }
    values: BasketValues; // = { discount: 0, gross: 0, net: 0, vat: 0, voucherDiscount: 0, delivery: 0, deliveryVat: 0, };
    payment?: {
        paymentMethod: any;
    };
    xeroQuoteId: string;
};

export const defaultBasketValues: BasketValues = {
    discount: 0,
    gross: 0,
    net: 0,
    vat: 0,
    voucherDiscount: 0,
    delivery: 0,
    deliveryVat: 0,
    preDiscount: {
        net: 0,
        vat: 0,
        gross: 0
    }
    // netPreDiscount: 0
} as BasketValues;

export const CalculateDiscountPrice = (pricing: SkuPrice, qty, onlyApplyDiscount = true): SkuPrice => {
    pricing.net = +pricing.preDiscount.net;

    if (onlyApplyDiscount && pricing.discountToUse === 'product' ||
        !onlyApplyDiscount && pricing.discount.product && pricing.discount.product.percentage > 0
    ) {
        pricing.discountToUse = 'product';
        pricing.net = +pricing.preDiscount.net - +pricing.discount.product.value;
    }
    if (onlyApplyDiscount && pricing.discountToUse === 'reward' ||
        !onlyApplyDiscount && pricing.discount.reward && +pricing.discount.reward.percentage > +pricing.discount.product.percentage
    ) {
        pricing.discountToUse = 'reward';
        pricing.net = +pricing.preDiscount.net - +pricing.discount.reward.value;
    }

    pricing.vat = +((+pricing.net / 100) * 20);
    pricing.gross = +pricing.net + +pricing.vat;

    pricing.totals = CalculatePricingTotals(pricing, qty);

    return pricing;
};

export const CalculatePricingTotals = (pricing, qty) => {
    // pricing.totals.netPreDiscount = +pricing.basePrice * qty;
    pricing.totals.preDiscount = {
        net: +pricing.basePrice * qty,
        vat: +((+pricing.basePrice * qty / 100) * 20),
        gross: +((+pricing.basePrice * qty / 100) * 20 + (+pricing.basePrice * qty)),
    };

    pricing.totals.productDiscount = 0;
    pricing.totals.rewardDiscount = 0;

    if (pricing.discountToUse !== 'none') {
        if (pricing.discountToUse === 'product') {
            pricing.totals.productDiscount = +pricing.discount.product.value * pricing.totals.qty;
        }
        if (pricing.discountToUse === 'reward') {
            pricing.totals.rewardDiscount = +pricing.discount.reward.value * pricing.totals.qty;
        }
        pricing.totals.discount = +pricing.discount[pricing.discountToUse].value * qty;
    }

    pricing.totals.net = pricing.net * qty;
    pricing.totals.vat = +((+pricing.totals.net / 100) * 20);
    pricing.totals.gross = +pricing.totals.net + +pricing.totals.vat;

    return pricing.totals;
};

export const PrepPricingPayload: (product: BespokeBuildProduct, isBespoke: boolean, userId?: string) => PricingPayload = (product, isBespoke = false, userId?) => {
    let extras: PricingPayloadExtras = {};
    if (product.cornersLR) {
        extras.cornersLR = { quantity: product.cornersLR };
    }

    if (product.cornersRR) {
        extras.cornersRR = { quantity: product.cornersRR };
    }

    if (product.ends) {
        extras.ends = { quantity: product.ends };
    }

    if (product.riserRequired) {
        extras.riser = {
            height: product.riser.height,
            quantity: product.quantity,
            thickness: product.riser.thickness,
        };
    }

    const itemToPrice: PricingPayload = {
        ...((isBespoke)
            ? {
                supplierId: '',
                rangeId: '',
                pricingMethod: '',
                widthRandom: product.widthRandom,
                lengthRandom: product.lengthRandom,
            } : {
                supplierId: product.supplierId,
                rangeId: product.rangeId,
                pricingMethod: product.pricingMethod.id,
                productId: product.id,
                productDetailId: product.productDetailId,
                skuId: product.skuId,
            }
        ),

        isBespoke: isBespoke,
        applyDiscount: true,
        quantity: product.quantity,
        dimensions: {
            l: {
                trueSize: product.length,
                size: +product.length / 1000,
                skuId: product.lengthSku ?? ''
            },
            w: {
                trueSize: product.width,
                size: +product.width / 1000,
                skuId: product.widthSku ?? ''
            },
            t: {
                trueSize: product.height,
                size: +product.height / 1000,
                skuId: product.heightSku ?? ''
            },
        },

        productType: product.type.id,
        finishId: product.finish.id ?? product.finish,

        sellUnit: product.sellUnit.id,
        margin: 0,
        discount: 0,
        finishes: {
            top: product.finishTop ? { skuId: product.finishTop.skuId } : {},
            bottom: product.finishBottom ? { skuId: product.finishBottom.skuId } : undefined,
            masonry: product.finishEdge ? { skuId: product.finishEdge.skuId } : undefined,
            weathering: product.weathering ? { skuId: product.weathering.skuId } : undefined
        },

        extras,
    } as PricingPayload;

    if (userId) {
        itemToPrice.customerId = userId;
    }

    return itemToPrice;
};

// Bespoke
export type ProductSellUnits = {
    id: string;
    name: string;
    isStandardPricing?: 1 | 0
    productType?: string;
    sellUnit?: string;
    sortOrder?: 1 | 0;
};

export type Restrictions = {
    width: {
        min: number;
        max: number;
    };
    length: {
        min: number;
        max: number;
    };
    height: {
        min: number;
        max: number;
    };
};

export type ProductTypeIds = 'paving' | 'piercaps' | 'coping' | 'steps' | 'setts';

export type ProductTypes = {
    adminImageUrl: string;
    allowAdditionalFinish: 1 | 0;
    allowBackEdge: 1 | 0;
    allowBespoke: 1 | 0;
    allowColouring: 1 | 0;
    allowCorners: 1 | 0;
    allowEdgeFinishing: 1 | 0;
    allowEnds: 1 | 0;
    allowFinishBottom: 1 | 0;
    allowFinishTop: 1 | 0;
    allowFreeDelivery: 1 | 0;
    allowPieces: 1 | 0;
    allowRandomLength: 1 | 0;
    allowRandomWidth: 1 | 0;
    allowRiser: 1 | 0;
    allowSize: 1 | 0;
    allowTexture: 1 | 0;
    allowTonneFinish: 1 | 0;
    allowWeathering: 1 | 0;
    bannerUrl: string;
    bespokeShowQuantity: 1 | 0;
    defaultIsRandom: number;
    deleted: 1 | 0;
    description: string;
    id: ProductTypeIds;
    imageUrl: string;
    lengthLabel: string;
    masonryLengthsForCalc: number;
    masonryWidthsForAdminCalc: number;
    masonryWidthsForCalc: number;
    name: string;
    productWeatheringLabel: string;
    randomLength: number;
    randomWidth: number;
    selectQuantityLabel: string;
    sellUnit: string;
    sellUnits: Array<ProductSellUnits>;
    seoDescription: string;
    seoKeywords: string;
    seoTitle: string;
    showInCM: number;
    showInMainMenu: number;
    sidesToCalcForFinishes: number;
    slug: string;
    sortOrder: number;
    status: number;
    widthLabel: string;
};

export type ProductFinish = {
    id: number;
    name: string;
    altName: null | string;
    skuId: string;
    productVariableId: number;
    costingMatrixBased: number;
    available: 1 | 0;
    deleted: 1 | 0;
    createdAt: string;
    size: number;
    cost: number;
    costType: string;
    imageUrl: string;
    sortOrder: number;
    costs: [
        {
            id: number;
            skuId: string;
            ProductVariableSlug: string;
            cost: number;
            rangeMin: 0;
            rangeMax: 9999;
            company: string;
        }
    ];
};

export type FinishesEdges = {
    id: number;
    name: string;
    altName: string;
    skuId: string;
    productVariableId: number;
    costingMatrixBased: number;
    available: 1 | 0;
    deleted: 1 | 0;
    createdAt: string;
    size: number;
    cost: string;
    costType: string;
    imageUrl: string;
    sortOrder: 1 | 0;
    costs: any;
};

export type ProductConfigSubType = {
    id: number;
    name: string;
    altName: string;
    skuId: string;
    productVariableId: number;
    costingMatrixBased: number;
    available: 1 | 0;
    deleted: 0 | 1;
    createdAt: string;
    size: number;
    cost: string;
    costType: string;
    imageUrl: string;
    sortOrder: number;
    costs: any;
    finishesEdge: Array<FinishesEdges>;
    finishesTop: [];
    finishesBottom: [];
    finishTop?: {
        front: string;
        back: string;
        left: string;
        right: string;
    };
    finishEdge?: {
        front: string;
        back: string;
        left: string;
        right: string;
    };
    finishBottom?: {
        front: string;
        back: string;
        left: string;
        right: string;
    };
    finishesAdditional: Array<{ name: string; altName: string; id: string }>;
};

export type ProductConfiguration = {
    id: number;
    name: string;
    altName: string;
    skuId: string;
    productVariableId: number;
    costingMatrixBased: number;
    available: number;
    deleted: number;
    createdAt: string;
    size: number;
    cost: string;
    costType: string;
    imageUrl: string;
    sortOrder: 1 | 0;
    costs: any;
    subTypes: Array<ProductConfigSubType>;
    allowRandom: 1 | 0;
    supplied: boolean;
    leadtimes: Array<any>;
    restrictions: Restrictions;
    sellUnit: ProductSellUnits;
    sellUnits: Array<ProductSellUnits>;
};

export type CostSQM = {
    id: number;
    rangeId: number;
    textureType: number;
    type: string;
    sizingType: string;
    minThickness: number;
    maxThickness: number;
    cost: number;
};

export type ProductRange = {
    id: number;
    supplier: string;
    collectionAddress: string;
    slug: string;
    name: string;
    bespokeName: string;
    deleted: 0 | 1;
    sortOrder: 0 | 1;
    available: 1 | 0;
    createdAt: string; // "2020-03-04T16:17:21.726Z",
    level: number;
    parent: number;
    showInMainMenu: 1 | 0;
    showInPreviewSlider: 0 | 1;
    tileText: string;
    bannerText: string;
    baseCost: string;
    baseWeight: number;
    bespokeImageUrl: string;
    imageUrl: string;
    bannerUrl: string;
    seoTitle: string;
    seoKeywords: string;
    seoDescription: string;
    lastUpdatedAt: string;
    description: string;
    showInCM: 1 | 0;
    margin: number;
    baseCostWithMargin: number;
    costsSQM: {
        random: Array<CostSQM>;
        setWidthRandom: Array<CostSQM>;
        setWidthSetLength: Array<CostSQM>;
    };
};

export type HeightValidation = {
    setBy: string;
    valid: boolean;
    min: number;
    max: number;
};

export type BespokeProductBase = {
    id?: string;
    productDetailId?: string;
    skuId?: string;
    type: undefined | ProductTypes;
    finish: undefined | ProductFinish;
    leadtimes: Array<any>;
    sellUnit: undefined | ProductSellUnits;
    quantity: number;
    length: undefined | number;
    width: undefined | number;
    height: undefined | number;
    lengthSku?: string;
    widthSku?: string;
    heightSku?: string;

    widthRandom: boolean;
    lengthRandom: boolean;
    cornersLR: number;
    cornersRR: number;
    ends: number;
    totalCost?: number,
    riserRequired: boolean;
    riser:
    | undefined
    | RiserType;
    unitCostBreakdown: {
        singlePieceSize: number;
        piecesRequired: number;
        squareMetres: number;
        linearMetres: number;

        baseUnitCost?: number;
        unitCost?: number;
        riserUnitCost?: number;
        weathering?: number;
        finishTop?: number;
        finishEdge?: number;
        finishBottom?: number;
        corner?: number;
        cornersLR?: number;
        cornersRR?: number;
        end?: number;
        totalCost?: number,
        totalUnitCost?: number;
        totalCharge?: number;
        sellConversion?: string;
        allowPieces?: number;
    };
    weathering: undefined | WeatheringType;
    purchaseUnit: string;
    pricePerSellUnit?: number;
    finishTop: FinishesEdges | '';
    finishEdge:
    | any
    | {
        front: FinishesEdges | '';
        back: FinishesEdges | '';
        left: FinishesEdges | '';
        right: FinishesEdges | '';
    }
    | FinishesEdges;
    finishBottom:
    | any
    | {
        front: FinishesEdges | '';
        back: FinishesEdges | '';
        left: FinishesEdges | '';
        right: FinishesEdges | '';
    }
    | FinishesEdges;
};

export type BespokeBuildProduct = BespokeProductBase & {
    subTypes: Array<any>;
    supplierId: string;

    sellUnits: undefined | Array<ProductSellUnits>;
    quantityMetres: number;
    weatheringLeft: number;

    copingLengthMeasure?: string;
    totalLength: number;
    weatheringRight: number;

    estDeliveryDate: Date;
    restrictions?: Restrictions;
    rangeId: number,
    pricingMethod: PricingMethodOrSellUnit
};

export type RiserType = {
    quantity: number;
    height: number;
    thickness: number;
    weightPerSellUnit: number;
    pricePerSellUnit: number;
    weight: number;
    price: number;
};

export type WeatheringType = {
    id: string;
    skuId: string; // '4W' | 'TW' | 'OW',
    name: string;
    altName: string;
    productVariableId: any;
    size: any;
    cost: any;
    costType: any;
    imageUrl: any;
};

export const defaultBespokeBuildProductBase: BespokeProductBase = {
    type: undefined,
    finish: undefined,
    leadtimes: [],
    sellUnit: undefined,
    quantity: 0,
    length: undefined,
    width: undefined,
    height: 40,
    cornersLR: 0,
    cornersRR: 0,
    ends: 0,
    widthRandom: false,
    lengthRandom: false,
    riserRequired: false,
    riser: undefined,
    purchaseUnit: '',
    unitCostBreakdown: {
        singlePieceSize: 0,
        piecesRequired: 0,
        squareMetres: 0,
        linearMetres: 0,
    },
    finishTop: '',
    finishEdge: {
        front: undefined,
        back: undefined,
        left: undefined,
        right: undefined
    },
    finishBottom: {
        front: '',
        back: '',
        left: '',
        right: '',
        name: '',
    },
    weathering: undefined,
};

export const defaultBespokeBuildProduct: BespokeBuildProduct = {
    ...JSON.parse(JSON.stringify(defaultBespokeBuildProductBase)),
    subTypes: [],
    supplierId: '',
    purchaseUnit: '',
    sellUnits: undefined,
    quantityMetres: 0,
    weatheringLeft: 0,
    totalLength: 0,
    weatheringRight: 0,
    estDeliveryDate: new Date(),
    unitCostBreakdown: {
        squareMetres: 0,
        linearMetres: 0,
        baseUnitCost: 0,
        unitCost: 0,
        singlePieceSize: 0,
        piecesRequired: 0,
        riserUnitCost: 0,
        weathering: 0,
        finishTop: 0,
        finishEdge: 0,
        finishBottom: 0,
        corner: 0,
        cornersLR: 0,
        cornersRR: 0,
        end: 0,
        totalUnitCost: 0,
        totalCharge: 0,
        sellConversion: '',
    }
};

export type BespokeBuild = {
    currentStep: 1 | 2 | 3 | 4 | 5;
    product: BespokeBuildProduct;
};

export const defaultBespokeBuild: BespokeBuild = {
    currentStep: 1,
    product: JSON.parse(JSON.stringify(defaultBespokeBuildProduct)),
} as BespokeBuild;


export type BasketBespoke = BespokeProductBase & {
    productCode: string,
    supplierId: string,
    rangeId: '' | number,
    dimensions?: [number, number, number],
    finishes: { top: undefined, bottom: undefined, edge: undefined, weathering: undefined },
    marginBespoke?: any,
    quantityMetres?: number,
    additionalRequirements?: {
        costType: string | 'PLM'
        cost: number
    }
    leadtime?: number,
    unitCost?: number,
    weightPerSellUnit?: number,
    weightTotal?: string,
    masonry?: any,
    range?: ProductRange
}

export type DimensionSize = {
    trueSize: number,
    size: number,
    skuId?: string
};

export type PricingPayload = {
    isBespoke: true,
    applyDiscount: boolean,
    dimensions: {
        l: DimensionSize,
        w: DimensionSize,
        t: DimensionSize,
    },
    supplierId: string,
    rangeId: number | '',
    productType: ProductTypeIds,
    finishId: number,
    pricingMethod?: any, // product.pricingMethod.id,
    sellUnit: string,
    margin: number,
    discount: number,
    quantity: number,
    finishes: {
        top?: undefined | { skuId: string },
        bottom?: undefined | { skuId: string },
        //edge?: undefined | { skuId: string },
        weathering?: undefined | { skuId: string }
        masonry?: undefined | { skuId: string }
    },
    widthRandom?: boolean, // not available for not bespoke
    lengthRandom?: boolean, // not available for not bespoke
    extras: PricingPayloadExtras,
    customerId?: string
    userId?: string
};

export type PricingPayloadExtras = {
    riser?: {
        height: number,
        quantity: number,
        thickness: number
    },
    cornersRR?: { quantity: number },
    cornersLR?: { quantity: number },
    ends?: { quantity: number }
};

export type PricingResult = {
    errors: Array<any>,
    charging: {
        costingMethod: string, // "CM",
        sellUnit: string // "P"
    },
    sizing: {
        PSM: number,
        LNM: number,
        CM: number,
        edgeFull: number,
        edgeToMason: number
    },
    weight: {
        supplierBaseWeight: number,
        total: number
    },
    quantity: {
        requested: {
            quantity: number,
            sizingType: string, // "P"
        },
        received: {
            P: number,
            PSM: number,
            LNM: number,
            CM: number
        }
    },
    baseStoneCost: {
        PSM: number,
        CM: number,
        P: number
    },
    costOfSale: {
        PSM: number,
        LNM: number,
        CM: number,
        P: number
    },
    costPerPiece: {
        baseStoneCost: number,
        totalCost: {
            piece: number,
            pieces: number
        }
    },
    sellValues: {
        P: number,
        CM: number,
        PLM: number,
        PSM: number
    },
    sellValue: number,
    leadtime: number,
    estDeliveryDate: string, // "2023-08-24T09:53:31.863Z",
    detail: Array<any>,
    rangeData: {
        id: number,
        name: string,
        bespokeName: string,
        bannerText: string,
        description: string,
        imageUrl: string,
        allowRandom: 1 | 0,
        margin: number
    },
    pricing: SkuPrice,
    bespokeId: string,
    pricingLog: Array<string>
}

export const defaultBasketBespoke: BasketBespoke = {
    productCode: '',
    supplierId: '',
    rangeId: '',
    finishes: { top: undefined, bottom: undefined, edge: undefined, weathering: undefined },
    ...JSON.parse(JSON.stringify(defaultBespokeBuildProductBase)),
};

export type ProductDetailsProduct = {
    jobLot: number;
    allowBreakage: number;
    id: number;
    pricingMethod: PricingMethodOrSellUnit;
    sellUnit: ProductSellUnits;
    productCode: string;
    name: string;
    subText: string;
    slug: string;
    type: Type;
    rangeId: number;
    groupId?: null;
    finish: number;
    margin: number;
    previewText: string;
    previewTileText: string;
    description: string;
    specialOffer: number;
    discount: number;
    status: number;
    deleted: number;
    supplier: Supplier;
    collectionAddress: string;
    createdBy: string;
    createdAt: string;
    ysRating: number;
    ysRatingComments: string;
    seoTitle: string;
    seoKeywords: string;
    seoDescription: string;
    lastUpdatedAt: string;
    deliveryAndReturns: string;
    minQty: number;
    recommendedJoint: string;
    tolerance: string;
    location: string;
    allowSamples: number;
    leadTime: string;
    calculatorShow: number;
    calculatorToUse: string;
    calculatorSqmPerTonne: string;
    calculatorWastage: number;
    allowFreeDelivery: number;
    domainId: string;
    showInCM: number;
    rangeName: string;
    descriptionString: string;
    guides: Guides;
    range: Range;
    domains?: (string)[] | null;
    priceBreaks?: (null)[] | null;
    allowBespoke: boolean;
    sample: Sample;
    productDetails?: (ProductDetailsEntity)[] | null;
    productGroup?: (ProductGroupEntity)[] | null;
    productImages?: (ProductImagesEntity)[] | null;
    textureImages?: (null)[] | null;
    checkoutImages?: (CheckoutImagesEntityOrColourImagesEntity)[] | null;
    colourImages?: (CheckoutImagesEntityOrColourImagesEntity)[] | null;
    inspoImages?: (null)[] | null;
    layImages?: (null)[] | null;
    basePricingUnits?: (BasePricingUnitsEntity)[] | null;
    leadtimes?: (LeadtimesEntity)[] | null;
    rebuildOptions?: (RebuildOptionsEntity)[] | null;
    riserRequired: boolean;
    riser: Riser;
}
export type PricingMethodOrSellUnit = {
    id: string;
    name: string;
}
export type Type = {
    id: string;
    name: string;
    masonryWidthsForCalc: number;
    masonryLengthsForCalc: number;
    masonryWidthsForAdminCalc: number;
}
export type Supplier = {
    id: string;
    town?: string;
    county?: string;
    postcode?: string;
    name?: string;
    addressId?: string;
    geo?: Geo;
}
export type Geo = {
    lat?: null;
    lng?: null;
}
export type Guides = {
    product: string;
    installation: string;
}
export type Range = {
    id: number;
    name: string;
}
export type Sample = {
    id: number;
    skuId: string;
    weight: number;
    margin: number;
    discount: number;
    basePrice: number;
    price: number;
    algoPrice: string;
    algoBasePrice: string;
    algoWeight: number;
    algoDiff: string;
    surcharge: string;
    createdAt: string;
    deleted: number;
    url: string;
    productId: number;
    status: number;
    qty: number;
    productRange: number;
    productType?: null;
    productFinish: number;
    minQty: number;
    maxQty: number;
    stockReserved: number;
    stockFree: number;
    stockHeld: number;
    isSample: number;
    lastAlgoRun?: null;
    algoError: number;
    algoRunRequired: number;
}
export type ProductDetailsEntity = {
    id: string;
    skuId: string;
    weight: number;
    margin: number;
    discount: number;
    basePrice: string;
    price: number;
    algoPrice: string;
    algoBasePrice: string;
    algoWeight: number;
    algoDiff: string;
    surcharge: string;
    createdAt: string;
    deleted: number;
    url: string;
    productId: number;
    status: number;
    qty: number;
    productRange: number;
    productType?: null;
    productFinish: number;
    minQty: number;
    maxQty: number;
    stockReserved: number;
    stockFree: number;
    stockHeld: number;
    isSample: number;
    lastAlgoRun: string;
    algoError: number;
    algoRunRequired: number;
    preDiscountPrice: number;
    discountValue: number;
    grossPrice: number;
    length: LengthOrWidthOrHeight;
    width: LengthOrWidthOrHeight;
    height: LengthOrWidthOrHeight;
    masonry: Masonry;
}
export type LengthOrWidthOrHeight = {
    id: number;
    name: string;
    altName?: null;
    skuId: string;
    productVariableId: number;
    costingMatrixBased: number;
    available: number;
    deleted: number;
    createdAt: string;
    size: number;
    cost: string;
    costType: string;
    imageUrl?: null;
    sortOrder: number;
}
export type Masonry = {
    id: number;
    name: string;
    altName?: null;
    skuId: string;
    productVariableId: number;
    costingMatrixBased: number;
    available: number;
    deleted: number;
    createdAt: string;
    size: number;
    cost: string;
    costType: string;
    imageUrl: string;
    sortOrder: number;
}
export type ProductGroupEntity = {
    id: number;
    name: string;
    slug: string;
}
export type ProductImagesEntity = {
    id?: number | null;
    url?: string | null;
    productCode?: string | null;
    type?: string | null;
    productId?: string | null;
    productVariableOption?: number | null;
    createdAt?: string | null;
    description?: string | null;
    competition?: number | null;
    pvoName?: null;
}
export type CheckoutImagesEntityOrColourImagesEntity = {
    id: number;
    url: string;
    productCode: string;
    type: string;
    productId: string;
    productVariableOption: number;
    createdAt: string;
    description: string;
    competition: number;
    pvoName?: null;
}
export type BasePricingUnitsEntity = {
    id: number;
    name: string;
    productId: number;
    length: number;
    width: number;
    height: number;
    sellUnit: string;
    weight: number;
    price: number;
    basePrice: number;
}
export type LeadtimesEntity = {
    id: number;
    productId: number;
    minUnit: number;
    maxUnit: number;
    days: number;
}
export type RebuildOptionsEntity = {
    id: number;
    slug: string;
    name: string;
    options?: (OptionsEntity)[] | null;
}
export type OptionsEntity = {
    id: number;
    productDetailId: number;
    slug: string;
    name: string;
    skuId: string;
    size: number;
    cost: string;
    productVariableId: number;
    costs?: (CostsEntity | null)[] | null;
}
export type CostsEntity = {
    id: number;
    skuId: string;
    ProductVariableSlug: string;
    cost: string;
    rangeMin: number;
    rangeMax: number;
    company: string;
}
export type Riser = {
    quantity: number;
    height: number;
    thickness: number;
    weightPerSellUnit: number;
    pricePerSellUnit: number;
    weight: number;
    price: number;
}


export type ProductSku = {
    id: string;
    skuId: string;
    productDetailId: string;
    product: Product;
    pricing: SkuPrice;
    qty: number;
    maxQty: number;
    leadtime: number;
    unitPrice: number;
    totalPrice: number;
    weight: number;
    surchargePercentage: number;
    surchargeValue: number;
    singlePieceSize: number;
    piecesRequired: number;
    quantityMetres: number;
    stockFree: number;
    length: SkuLengthOrWidthOrHeight;
    width: SkuLengthOrWidthOrHeight;
    height: SkuLengthOrWidthOrHeight;
    masonry: SkuMasonry;
}

export const defaultSkuProduct = () => {
    return {
        id: '',
        skuId: '',
        productDetailId: '',
        product: {
            id: '',
            productCode: '',
            name: '',
            slug: '',
            image: '',
        },
        pricing: undefined,
        qty: 10,
        maxQty: 0,
        leadtime: 10,
        unitPrice: 0,
        totalPrice: 0,
        weight: 0,
        surchargePercentage: 0,
        surchargeValue: 0,
        singlePieceSize: 0,
        piecesRequired: 0,
        quantityMetres: 0,
        length: {
            size: 0
        },
        width: {
            size: 0,
        },
    } as ProductSku
}

export type Product = {
    id: string;
    productCode: string;
    name: string;
    slug: string;
    image: string;
}
export type SkuLengthOrWidthOrHeight = {
    id: number;
    productDetailId: number;
    slug: string;
    name: string;
    skuId: string;
    size: number;
    cost: string;
    productVariableId: number;
    costs?: (null)[] | null;
}
export type SkuMasonry = {
    id: number;
    productDetailId: number;
    slug: string;
    name: string;
    skuId: string;
    size: number;
    cost: string;
    productVariableId: number;
    costs?: (CostsEntity)[] | null;
}
