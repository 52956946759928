import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Store } from "../../store";
import { AlertService } from "../../services/alert.service";
import { AlertData } from "../../data-types/alert-data";

@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
    @Input() data: AlertData;
    @Output() setStep = new EventEmitter();
    @Output() confirmAction = new EventEmitter();

    constructor(
        private store: Store,
        private alertService: AlertService
    ) {
    }

    close() {
        this.alertService.clearMessage();
        if (this.data.confirmAction && this.data.confirmAction.closeCb) {
            this.data.confirmAction.closeCb(this.data.confirmAction.data);
        }
        if (this.data.simple && this.data.simple.closeCb) {
            this.data.simple.closeCb(this.data.simple.data);
        }
        if (this.data.customAlert && this.data.customAlert.closeCb) {
            this.data.customAlert.closeCb(this.data.customAlert.data);
        }
        if (this.data.resolve) {
            this.data.resolve("Alert rejected");
        }
    }

    acceptClick() {
        this.alertService.clearMessage();
        if (this.data.confirmAction && this.data.confirmAction.cancelCb) {
            this.data.confirmAction.cancelCb(this.data.confirmAction.data);
        }
        if (this.data.simple && this.data.simple.confirmCb) {
            this.data.simple.confirmCb(this.data.simple.data);
        }
        if (this.data.customAlert && this.data.customAlert.cancelCb) {
            this.data.customAlert.cancelCb(this.data.customAlert.data);
        }
        if (this.data.resolve) {
            this.data.resolve("Alert closed");
        }
    }

    confirm() {
        if (this.data.customAlert && this.data.customAlert.canConfirmCb && this.data.customAlert.canConfirmCb() !== true) {
            return; // do nothing if it's not valid yet
        }

        this.alertService.clearMessage();
        if (this.data.confirmAction && this.data.confirmAction.confirmCb) {
            this.data.confirmAction.confirmCb(this.data.confirmAction.data);
        }
        if (this.data.customAlert && this.data.customAlert.confirmCb) {
            this.data.customAlert.confirmCb(this.data.customAlert.data);
        }
        if (this.data.resolve) {
            this.data.resolve("Alert closed");
        }
    }

}
