import { NgModule } from '@angular/core';
import { DateAgoPipe } from './date-ago.pipe';
import { SafeHTMLPipe } from './safe-html.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { LeadtimePipe } from './leadtime.pipe';
import { AutolinkerPipe } from './autolinker.pipe';
import { ConsolePipe } from "./console.pipe";
import { AddressPipe } from './format-address.pipe';
import { hasFlagPipe } from './hasFlag.pipe';
import { StrReplacePipe } from './str-replace.pipe';

@NgModule({
    declarations: [
        SafeHTMLPipe,
        DateAgoPipe,
        StripHtmlPipe,
        LeadtimePipe,
        AutolinkerPipe,
        ConsolePipe,
        AddressPipe,
        hasFlagPipe,
        StrReplacePipe
    ],
    imports: [],
    exports: [
        DateAgoPipe,
        SafeHTMLPipe,
        StripHtmlPipe,
        LeadtimePipe,
        AutolinkerPipe,
        ConsolePipe,
        AddressPipe,
        hasFlagPipe,
        StrReplacePipe
    ],
})
export class PipesModule {
}
