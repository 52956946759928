import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '../store';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FavouritesService {

    env = environment;

    constructor(
        private apiService: ApiService,
        private http: HttpClient,
        private store: Store,
    ) {
    }

    addToFavourites(productId) {
        let favourites: any[] = JSON.parse(localStorage.getItem('favourites'));
        if (!favourites) {
            favourites = [];
        }
        if (favourites.indexOf(productId) < 0) {
            favourites.push(productId);
        }
        localStorage.setItem('favourites', JSON.stringify(favourites));
        this.store.set('favourites', favourites);

        this.saveRemote();
    }

    deleteFavourite(productId) {
        const favourites: any[] = JSON.parse(localStorage.getItem('favourites'));
        const idx = favourites.indexOf(productId);
        if (idx >= 0) {
            favourites.splice(idx, 1);
        }
        localStorage.setItem('favourites', JSON.stringify(favourites));
        this.store.set('favourites', favourites);

        this.saveRemote();
    }

    saveRemote() {
        const favourites: any[] = JSON.parse(localStorage.getItem('favourites'));
        const dataToSend = {
            favourites,
            sessionId: localStorage.getItem('sessionId'),
            tmpSessionId: localStorage.getItem('tmpSessionId')
        };
        return this.http.post(this.env.apiPath + 'products/favourites/', dataToSend, this.apiService.getHttpOptions())
            .subscribe(data => {
                return data;
            });
    }

    get() {
        const user = localStorage.user;
        let userId = '';

        if (user) {
            userId = user;
        }
        if (!user) {
            userId = localStorage.getItem('sessionId');
        }
        return this.http.get(this.env.apiPath + 'products/favourites/' + userId, this.apiService.getHttpOptions())
            .pipe(
                map((data: any) => {
                    const favourites = [];
                    data.data.forEach(f => {
                        favourites.push(f.id);
                    });
                    localStorage.setItem('favourites', JSON.stringify(favourites));
                    this.store.set('favourites', favourites);
                    return data;
                },
                    catchError(this.apiService.handleError)
                ));
    }

}
