import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';
import { UserQuoteStatus } from '../data-types/user-data';
import { Store } from '../store';
import { GaService } from './ga.service';
import { ToolsService } from './tools.service';
import { RewardService } from './reward.service';
import {
    defaultSkuPriceTotals,
    SkuPriceTotals,
    Basket,
    BasketItem,
    defaultBasketItem,
    defaultBasketValues
} from '../data-types/basket.data';
import { UserService } from './user.service';
import { ApiAuthHttpClient } from '../http/ApiAuthHttpClient';
import { AuthHttpClient } from '../http/AuthHttpClient';
import { isPlatformBrowser } from '@angular/common';
import { ProductService } from './product.service';

@Injectable({
    providedIn: 'root',
})
export class BasketService {
    private _userBaskets = [];
    private basketRequest: Subscription = null;

    constructor(
        private http: ApiAuthHttpClient,
        private authHttp: AuthHttpClient,
        private store: Store,
        private toolsService: ToolsService,
        private rewardsService: RewardService,
        private userService: UserService,
        private gaService: GaService,
        private productService: ProductService,
        @Inject(PLATFORM_ID) platformId: {}
    ) {
        this.store
            .select<any>('user')
            .pipe(distinctUntilChanged())
            .subscribe((storeUser: any) => {
                let basket = this.store.selectForLocal<Basket>('basket');
                if (basket.items.length === 0) {
                    // the case when basket not loaded yet
                    return;
                }

                const sub = storeUser ? this.rewardsService.userSummary(storeUser.id) : of(undefined);

                sub.subscribe(async (user: any) => {
                    let rewardDiscount = 0;

                    if (storeUser?.rewardDiscount) {
                        rewardDiscount = +storeUser.rewardDiscount;
                    }
                    if (user) {
                        rewardDiscount = +user.currentPercentage;
                    }

                    basket = this.store.selectForLocal<Basket>('basket');
                    basket.items = basket.items.map((item) => {
                        if (!item.product.isBespoke) {
                            item.pricing.discount.reward = {
                                percentage: rewardDiscount,
                                value: (+item.pricing.discount.preDiscountValue / 100) * item.pricing.discount.reward.percentage
                            };
                        }
                        return item;
                    });

                    await this.calculateBasketTotal(basket, storeUser);
                });
            });

        if (isPlatformBrowser(platformId)) {
            if (localStorage.getItem('tempGuestUserId')) {
                this.userService
                    .findOne(localStorage.getItem('tempGuestUserId'))
                    .subscribe(({ data }: any) => this.store.set('guestUser', data));
            }
        }
    }

    getBasketId() {
        let basketId;
        const ysBasketId = localStorage.getItem('ysBasketId');
        if (ysBasketId) {
            basketId = ysBasketId;
        } else {
            basketId = this.toolsService.newUUID();
            localStorage.setItem('ysBasketId', basketId);
        }
        this.store.set('basketId', basketId);
        return basketId;
    }

    public getQuoteBasket(basketId: string): Observable<any> {
        return this.http.get(env.apiPath + 'basket/quote/' + basketId).pipe(map((data: any) => data.data));
    }

    public getQuotesBasket(userId: string): Observable<any> {
        return this.http.get(env.apiPath + 'basket/quotes/' + userId).pipe(map((data: any) => data.data));
    }

    public updateBasketQuote(basketId: string, status: UserQuoteStatus): Observable<any> {
        return this.http
            .put(env.apiPath + 'basket/quote/status', {
                basketId,
                quoteStatus: status,
            })
            .pipe(map((data: any) => data.data));
    }

    public getBasketData(basketId: string, setToStore = true): Observable<any> {
        return this.http.get(env.apiPath + 'basket/' + basketId);
    }

    public getBasket(basketId: string, setToStore = true): Observable<Basket> {
        return this.getBasketData(basketId).pipe(
            map(({ data }: any) => {
                let basketData: Basket;
                if (data) {
                    basketData = data as Basket;
                    if (!basketData.samples) {
                        basketData.samples = [];
                    }
                    if (!basketData.restrictions) {
                        basketData.restrictions = {
                            slope: false,
                            gravel: false,
                            boards: false,
                            offload: false,
                        };
                    }
                    if (setToStore) {
                        this.store.set('basket', basketData);
                        localStorage.setItem('ysBasket', JSON.stringify(basketData));
                    }
                } else {
                    basketData = this.store.selectForLocal<Basket>('basket');
                    if (!basketData.samples) {
                        basketData.samples = [];
                    }
                    if (!basketData.restrictions) {
                        basketData.restrictions = {
                            slope: false,
                            gravel: false,
                            boards: false,
                            offload: false,
                        };
                    }
                    if (setToStore) {
                        this.store.set('basket', basketData);
                        localStorage.setItem('ysBasket', JSON.stringify(basketData));
                    }
                }
                return basketData;
            })
        );
    }

    async addToBasket(item: BasketItem, replaceIndex?) {
        let maxSamples = 3;

        const user = this.store.selectForLocal('user');

        if (user && user.accountType === 'trade') {
            maxSamples = 10;
        }

        let basket: Basket = this.store.selectForLocal<Basket>('basket');
        if (!basket.samples) {
            basket.samples = [];
        }
        if (!basket.samples.length && !basket.items.length && !user) {
            // clearing user data from the storages
            (await (await this.toolsService.clearTempStorages()).clearUserStorages()).sessionId();
            this.toolsService.logoutSessionId();
            basket = this.emptyBasket();
        }

        // check for duplicate products and add qty if found (non bespoke only);
        const dupeFound = +replaceIndex >= 0 ? false : this.dupeCheck(item, basket);

        const lastBasketItemAdded = {
            isDupe: dupeFound,
            isSample: item.sample,
            sampleCount: basket.samples.length,
            item,
        };
        this.store.set('lastBasketItemAdded', lastBasketItemAdded);

        if (item.sample) {
            if (!dupeFound && basket.samples.length < +maxSamples) {
                if (+replaceIndex >= 0) {
                    basket.samples.splice(replaceIndex, 1, item);
                } else {
                    item.pricing.totals = this.calculateLineTotals(item, true);

                    basket.samples.push(item);
                }
            }
        }

        if (!item.sample) {
            if (!dupeFound) {
                if (+replaceIndex >= 0) {
                    basket.items.splice(replaceIndex, 1, item as BasketItem);
                } else {
                    basket.items.push(item as BasketItem);
                }
            }
        }

        this.checkForSampleOnly(basket);
        await this.calculateBasketTotal(basket);
        this.gaService.addToBasket(item);
    }

    addBespokeItems(lists) {
        // something old used here not sure how and if even it works
        let basket: Basket = this.store.selectForLocal<Basket>('basket');
        for (let l = 0; l < lists.length; l++) {
            for (let i = 0; i < lists[l].items.length; i++) {
                const sku: BasketItem = {
                    ...defaultBasketItem(),
                    qty: 10,
                };

                sku.product = lists[l].items[i];
                sku.product.isBespoke = true;
                sku.sample = false;
                sku.product.id = '0';
                sku.product.productCode = '';
                sku.product.name = '';
                sku.product.slug = '';
                sku.product.jobLot = 0;
                sku.product.allowBreakage = 0;
                sku.product.supplier = {
                    id: lists[l].items[i].range.supplier,
                };
                sku.product.rangeId = sku.product.range.id;

                sku.sellUnit = lists[l].items[i].sellUnit;
                sku.product.image = lists[l].items[i].range.imageUrl;
                sku.product.name = `Bespoke ${lists[l].items[i].range.name} ${sku.product.type.name}`;
                sku.product.productCode = 'bespoke';
                if (sku.product.type.sellUnits.length) {
                    sku.sellUnit = sku.product.type.sellUnits[0];
                }
                sku.skuId = '';
                sku.productDetailId = 0;
                sku.qty = +lists[l].items[i].quantity;
                sku.leadtime = +lists[l].items[i].leadtime;
                // pallet weight was being calculated incorrectly as it was multiplying by the QTY in the courier API method
                // sku.weight = +item.weightPerSellUnit * +item.quantity;
                sku.weight = +lists[l].items[i].weightPerSellUnit;
                sku.sellUnit = lists[l].items[i].sellUnit;
                sku.unitPrice = +lists[l].items[i].unitCost;
                sku.totalPrice = +lists[l].items[i].unitCostBreakdown.totalCost;

                sku.piecesRequired = +lists[l].items[i].unitCostBreakdown.piecesRequired;
                sku.singlePieceSize = +lists[l].items[i].unitCostBreakdown.singlePieceSize;
                sku.weight = +lists[l].items[i].weightTotal;
                sku.quantityMetres = +lists[l].items[i].unitCostBreakdown.squareMetres;

                if (lists[l].items[i].sellUnit.id === 'PLM') {
                    sku.quantityMetres = +lists[l].items[i].unitCostBreakdown.linearMetres;
                }
                if (lists[l].items[i].sellUnit.id === 'PSM') {
                    sku.quantityMetres = +lists[l].items[i].unitCostBreakdown.squareMetres;
                }
                if (lists[l].items[i].sellUnit.id === 'P') {
                    sku.quantityMetres = +lists[l].items[i].unitCostBreakdown.squareMetres;
                }

                delete sku.product.range;

                // this.addToBasket(sku);
                basket.items.push(sku);
                basket = this.checkForSampleOnly(basket);
            }
        }
        this.calculateBasketTotal().then();
    }

    dupeCheck(item, basket: Basket): boolean {
        let dupeFound = false;

        if (!item.sample) {
            const basketItemI = this.getBasketItemBySkuId(item.product.productCode + item.skuId, basket);

            if (basketItemI !== false) {
                if (!item.product.isBespoke) {

                    if (item.product.jobLot) {

                        if (basket.items[basketItemI].qty > basket.items[basketItemI].maxQty) {
                            basket.items[basketItemI].qty = basket.items[basketItemI].maxQty;
                            basket.items[basketItemI].maxQtyExceeded = true;
                        }
                    } else {
                        basket.items[basketItemI].qty = +basket.items[basketItemI].qty + +item.qty;
                        basket.items[basketItemI].maxQtyExceeded = false;

                        basket.items[basketItemI].piecesRequired = Math.ceil(+basket.items[basketItemI].qty / +basket.items[basketItemI].singlePieceSize);
                        basket.items[basketItemI].totalPrice = +(+basket.items[basketItemI].qty * +basket.items[basketItemI].unitPrice).toFixed(2);

                        basket.items[basketItemI].unitPriceDiscount = +(
                            +basket.items[basketItemI].unitPriceGross - +basket.items[basketItemI].unitPrice
                        ).toFixed(2);
                        basket.items[basketItemI].totalDiscount = +basket.items[basketItemI].unitPriceDiscount * +basket.items[basketItemI].qty;
                        basket.items[basketItemI].totalPricePreDiscount = +basket.items[basketItemI].unitPriceGross * +basket.items[basketItemI].qty;

                        basket.items[basketItemI].pricing.totals = this.calculateLineTotals(basket.items[basketItemI], false);

                    }
                } else {
                    basket.items.splice(basketItemI, 1, item as BasketItem); // no mods for bespoke items
                }

                dupeFound = true;
            }
        } else {
            for (let i = 0; i < basket.samples.length; i++) {
                if (
                    basket.samples[i].sample &&
                    basket.samples[i].product.finishId === item.product.finishId &&
                    basket.samples[i].product.rangeId === item.product.rangeId
                ) {
                    dupeFound = true;
                }
            }
        }

        return dupeFound;
    }

    getBasketItemBySkuId(id: string, basket?: Basket): false | number {
        if (!basket) {
            basket = this.store.selectForLocal<Basket>('basket');
        }
        for (let i = 0; i < basket.items.length; i++) {
            if (!basket.items[i].sample &&
                basket.items[i].product.productCode + basket.items[i].skuId === id
            ) {
                return i;
            }
        }

        return false;
    }

    saveBasketChanges(basket: Basket, src?) {
        this.store.set('basket', basket); // !!!! THIS LINE HAS TO BE ABOVE before saving to DB
        this.saveBasketToStorage(basket).then();
    }

    setGuest(v: boolean) {
        const basket: Basket = this.store.selectForLocal<Basket>('basket');
        basket.guest = v;
        this.saveBasketChanges(basket, 'internal');
    }

    getStripeSession() {
        const basket: Basket = this.store.selectForLocal<Basket>('basket');
        return this.http.post(env.apiPath + 'orders/stripe-session', basket).pipe(map((data: any) => data));
    }

    calculateBasketTotal(basket?: Basket, storeUser?): Promise<Basket> {
        if (!basket) {
            basket = this.store.selectForLocal<Basket>('basket');
        }
        if (!storeUser) {
            storeUser = basket.customer;
        }

        //const user = this.store.selectForLocal('user');
        //let basket: Basket = this.store.selectForLocal<Basket>('basket');
        // basket = this.calculateBasketObjectTotal(basket, user);
        // this.saveBasketChanges(basket, 'internal');
        basket.values = {
            ...basket.values,
            ...{
                gross: 0,
                vat: 0,
                net: 0,
                productDiscount: 0,
                voucherDiscount: 0,
                rewardDiscount: 0,
                adminDiscount: 0,
            },
        };

        if (!basket.items.length) {
            basket.values.delivery = 0;
            basket.values.deliveryVat = 0;
        }
        if (basket.sampleOnly) {
            basket.values.delivery = 0;
            basket.values.deliveryVat = 0;
        }

        /* DISCOUNTS logic 12/04/2022
         * If a discount code is used in conjunction with a current discount already on a customers account,
         * always allocate the highest discount between the automatic and manually added discount code. So example being,
         * if a customer has a 10% discount on their account all year round, and try to use a discount code that is 15%
         * off then the customer will only get 15% off - NOT 10% plus 15%. If the discount code is monetary (e.g. £50 off),
         * they will get whichever is the highest discount (£50 off or 10%), NOT both.
         * */
        return new Promise((res, rej) => {
            let startObs = this.http.post(env.apiPath + 'orders/totals', {
                items: basket.items.map(({ pricing, skuId, product, qty }) => ({ product, pricing, skuId, qty })),
                delivery: { amount: basket.values.delivery, vat: basket.values.deliveryVat },
                voucher: basket.voucher ?? null,
                rewardsDiscountPercentage: storeUser ? storeUser.rewardDiscount ?? 0 : 0,
                discount: basket.discount
                    ? { discountValue: basket.discount.value.toString(), discountType: basket.discount.type }
                    : null,
            });

            startObs.subscribe(({ data }: any) => {
                basket.values = {
                    ...basket.values,
                    ...data.totals,
                };

                basket.items = basket.items.map((basketItem: BasketItem) => {
                    const foundItem = data.items.find(item => item.product.id + item.skuId === basketItem.product.id + basketItem.skuId)
                    if (foundItem) {
                        basketItem.pricing = foundItem.pricing;
                    }

                    return basketItem;
                });

                if (data.discountApplied === 'product' || data.discountApplied === 'reward') {
                    delete basket.voucher;
                    basket.voucherCodeEntered = '';
                }

                this.saveBasketChanges(basket, 'calculateBasketTotal');
                res(basket);
            });
        });
    }

    calculateLineTotals(itemTotals, isSample): SkuPriceTotals {
        const totals: SkuPriceTotals = defaultSkuPriceTotals();

        if (isSample) {
            return totals;
        }

        totals.qty = itemTotals.qty;
        totals.preDiscount.net = +itemTotals.pricing.preDiscount.net * totals.qty;
        totals.preDiscount.vat = +itemTotals.pricing.preDiscount.vat * totals.qty;
        totals.preDiscount.gross = +itemTotals.pricing.preDiscount.gross * totals.qty;

        totals.net = +itemTotals.pricing.net * totals.qty;
        totals.vat = +totals.net * 0.2;
        totals.gross = +itemTotals.pricing.net + totals.vat;

        if (itemTotals.pricing.discountToUse === 'reward') {
            totals.rewardDiscount = +itemTotals.pricing.discount.reward.value * totals.qty;
            totals.discount = totals.rewardDiscount;
        }
        if (itemTotals.pricing.discountToUse === 'product') {
            totals.productDiscount = +itemTotals.pricing.discount.product.value * totals.qty;
            totals.discount = totals.productDiscount;
        }

        return totals;
    }

    deleteBasketItem(idx) {
        let basket: Basket = this.store.selectForLocal<Basket>('basket');

        this.gaService.removeFromBasket(basket.items[idx]);

        basket.items.splice(idx, 1);

        basket = this.checkForSampleOnly(basket);
        this.calculateBasketTotal().then();
    }

    emptyBasketItems() {
        const basket: Basket = this.store.selectForLocal<Basket>('basket');
        while (basket.items.length > 0) {
            this.deleteBasketItem(0);
        }
    }

    deleteSampleItem(idx) {
        let basket: Basket = this.store.selectForLocal<Basket>('basket');
        basket.samples.splice(idx, 1);

        basket = this.checkForSampleOnly(basket);
        this.calculateBasketTotal().then();
    }

    toggleSeparateShipment(idx) {
        const basket: Basket = this.store.selectForLocal<Basket>('basket');
        basket.items[idx].separateShipment = !basket.items[idx].separateShipment;
        return basket;
    }

    checkForSampleOnly(basket: Basket): Basket {
        basket.sampleOnly = !basket.items.length;
        return basket;
    }

    setAddress(address) {
        const basket: Basket = this.store.selectForLocal<Basket>('basket');
        const addressType = this.store.selectForLocal('addressType');

        if (addressType === 'billing') {
            basket.billingAddress = address;
        } else {
            basket.deliveryAddress = address;
        }
        this.store.set('basket', basket);
        this.calculateBasketTotal().then();
    }

    emptyBasket() {
        const basket: Basket = this.store.selectForLocal<Basket>('basket');
        basket.source = 'public';
        basket.items = [];
        basket.samples = [];
        basket.potentialQty = 0;
        basket.voucher = undefined;
        basket.voucherCodeEntered = '';
        basket.deliveryInstructions = '';
        basket.differentDeliveryAddress = false;
        basket.deliveryInstructions = '';
        basket.token = undefined;
        basket.customer = undefined;
        basket.deliveryAddress = undefined;
        basket.billingAddress = undefined;
        basket.createdBy = undefined;
        basket.sampleOnly = false;

        this.store.set('deliveryAddresses', []);
        this.store.set('basketId', '');
        this.store.set('basket', basket);

        return basket;
    }

    clearBasket() {
        const basket: Basket = this.store.selectForLocal<Basket>('basket');
        basket.source = 'public';
        basket.items = [];
        basket.samples = [];
        basket.potentialQty = 0;
        basket.voucher = undefined;
        basket.voucherCodeEntered = '';
        basket.deliveryInstructions = '';
        basket.differentDeliveryAddress = false;
        basket.deliveryInstructions = '';
        basket.token = undefined;
        basket.sampleOnly = false;
        basket.discount = {
            type: 'pound',
            value: 0,
        };
        basket.values = {
            ...JSON.parse(JSON.stringify(defaultBasketValues)),
        };

        this.store.set('basket', basket);
        localStorage.setItem('ysBasket', JSON.stringify(basket));
    }

    renewBasket() {
        const currentBasketId = this.getBasketId();

        const basketId = this.toolsService.newUUID();
        localStorage.setItem('ysBasketId', basketId);
        this.store.set('basketId', basketId);

        const basket: Basket = this.store.selectForLocal<Basket>('basket');
        const userId = localStorage.getItem('user');

        localStorage.setItem('basketRef', JSON.stringify(basket));

        const basketRef = localStorage.getItem('basketRef');

        const userBasket = this._userBaskets.find((userBasket) => userBasket.userId === userId);

        if (!userBasket) {
            this._userBaskets.push({ userId, basket: JSON.parse(basketRef) });
        } else {
            const userBasketIndex = this._userBaskets.findIndex((userBasket) => userBasket.userId === userId);

            const latestUserBasket = JSON.parse(localStorage.getItem('basketRef'));

            this._userBaskets[userBasketIndex].basket = latestUserBasket;
        }

        this.store.set('userBaskets', this._userBaskets);
        localStorage.setItem('userBaskets', JSON.stringify(this._userBaskets));

        this.clearBasket();

        return this.http.delete(env.apiPath + 'basket/' + currentBasketId).subscribe();
    }

    clearUserFromBasket() {
        const basket: Basket = this.store.selectForLocal<Basket>('basket');
        basket.deliveryInstructions = '';
        basket.token = undefined;
        basket.deliveryAddress = undefined;
        basket.billingAddress = undefined;
        basket.deliveryPostcode = '';
        basket.differentDeliveryAddress = false;
        basket.customer = undefined;
        basket.createdBy = undefined;
        basket.guest = true;
        basket.shipments = [];
        basket.values.delivery = 0;
        basket.values.deliveryVat = 0;
        basket.paymentMethod = 'CC';
        delete basket.intent;
        this.store.set('basket', basket);
        this.calculateBasketTotal().then();
    }

    deleteIntent() {
        const basket: Basket = this.store.selectForLocal<Basket>('basket');
        delete basket.intent;
        this.store.set('basket', basket);
    }

    async saveBasketToStorage(basket) {
        localStorage.setItem('ysBasket', JSON.stringify(basket));

        const basketId = this.getBasketId();

        const dataToSend: any = {
            basket,
        };
        if (basketId) {
            dataToSend.basketId = basketId;
        }
        if (this.basketRequest) {
            this.basketRequest.unsubscribe();
        }

        return new Promise((res, rej) => {
            this.basketRequest = this.authHttp
                .post(env.apiPath + 'basket', dataToSend)
                .subscribe(({ data }: any) => res(data));
        });
    }

    applyVoucher(voucherCode) {
        return this.http.get(env.apiPath + 'voucher/code/' + voucherCode).pipe(map((data: any) => data));
    }

    removeVoucher() {
        const basket: Basket = this.store.selectForLocal<Basket>('basket');
        basket.voucher = undefined;
        basket.voucherCodeEntered = '';
        this.store.set('basket', basket);
        // Todo implement remove voucher Endpoint
        this.calculateBasketTotal().then();
    }

    processBasket(basket?: Basket) {
        basket = basket ?? this.store.selectForLocal<Basket>('basket');
        const basketId = this.getBasketId();
        const dataToSend = {
            basket,
            basketId,
        };

        // if (env.allowSeparateShipments) {
        //     return this.http
        //         .post(env.apiPath + 'orders/create/separate-shipments/', dataToSend)
        //         .pipe(map((data: any) => data));
        // } else {
        //     return this.http.post(env.apiPath + 'orders/create/', dataToSend).pipe(map((data: any) => data));
        // }
        return this.http.post(env.apiPath + 'orders/create/', dataToSend).pipe(map((data: any) => data));
    }

    processBasket2Order(basket?): Observable<any> {
        return this.processBasket(basket).pipe(
            tap(() => {
                // do cleaning basket stuff
                this.deleteIntent();
                this.renewBasket();
            }),
            map(({ data: orderResult }: any) => orderResult)
        );
    }

    buildProductsArray(basket) {
        const products = [];

        for (let i = 0; i < basket.items.length; i++) {
            let rangeName = '';
            if (basket.items[i].product.range) {
                rangeName = basket.items[i].product.range.name;
            }

            const product = {
                name: basket.items[i].product.name,
                id: basket.items[i].skuId,
                price: basket.items[i].unitPrice,
                brand: 'Yorkstone Supplies',
                list_name: rangeName,
                category: basket.items[i].product.typeId,
                variant: basket.items[i].product.typeId,
                quantity: basket.items[i].qty,
                discount: basket.items[i].totalDiscount,
            };
            products.push(product);
        }

        // and samples
        for (let i = 0; i < basket.samples.length; i++) {
            let rangeName = '';

            if (basket.samples[i].product.range) {
                rangeName = basket.samples[i].product.range.name;
            }

            const product = {
                name: `${basket.samples[i].product.name} - sample`,
                id: basket.samples[i].skuId,
                price: 0,
                brand: 'Yorkstone Supplies',
                list_name: rangeName,
                category: basket.samples[i].product.typeId,
                variant: basket.samples[i].product.typeId,
                quantity: basket.samples[i].qty,
            };
            products.push(product);
        }

        return products;
    }

    buildProductsArrayFbq(basket) {
        const products = [];

        for (let i = 0; i < basket.items.length; i++) {
            let rangeName = '';
            if (basket.items[i].product.range) {
                rangeName = basket.items[i].product.range.name;
            }

            const product = {
                content_name: basket.items[i].product.name,
                id: basket.items[i].product.id,
                sku: basket.items[i].skuId,
                quantity: basket.items[i].qty,
            };
            products.push(product);
        }

        // and samples
        for (let i = 0; i < basket.samples.length; i++) {
            let rangeName = '';

            if (basket.samples[i].product.range) {
                rangeName = basket.samples[i].product.range.name;
            }

            const product = {
                name: `${basket.samples[i].product.name} - sample`,
                id: basket.samples[i].product.id,
                sku: basket.samples[i].skuId,
                price: 0,
                brand: 'Yorkstone Supplies',
                list_name: rangeName,
                category: basket.samples[i].product.typeId,
                variant: basket.samples[i].product.typeId,
                quantity: basket.samples[i].qty,
            };
            products.push(product);
        }

        return products;
    }

    checkBasketItemStock() {
        const basket = this.store.selectForLocal<Basket>('basket');
        for (let i = 0; i < basket.items.length; i++) {
            if (basket.items[i].product.jobLot) {

                this.productService.findJoblotStockLevel(basket.items[i].product.id, basket.items[i].skuId).subscribe(data => {
                    if (+basket.items[i].qty > +data.data.stockFree) {
                        basket.items[i].lowStockWarning = true;
                        basket.items[i].lowStockLevel = +data.data.stockFree;
                        basket.items[i].itemAvailable = data.data.itemAvailable;
                    }
                })
            }
        }
        this.saveBasketChanges(basket)
    }
}
