import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {FACEBOOK_PIXEL_SETTINGS_TOKEN} from '../token/facebook-pixel-settings-token';
import {GOOGLE_ANALYTICS_SETTINGS_TOKEN} from '../token/google-analytics-settings-token';
import {CookieService} from 'ngx-cookie';
import {isPlatformBrowser} from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class CookieConsentService {

    constructor(
        private cookieService: CookieService,
        @Inject(GOOGLE_ANALYTICS_SETTINGS_TOKEN) private readonly gaSettings,
        @Inject(FACEBOOK_PIXEL_SETTINGS_TOKEN) private readonly fbqSettings,
        @Inject(PLATFORM_ID) private readonly platformId: {},
    ) {
    }

    getGaEnabled() {
        return this.gaSettings.isEnabled;
    }

    setGaEnabled(value: boolean) {
        this.gaSettings.isEnabled = value;
    }

    getFbqEnabled() {
        return this.fbqSettings.isEnabled;
    }

    setFbqEnabled(value: boolean) {
        this.fbqSettings.isEnabled = value;
    }

    getConsentDialogEnabled() {
        if (isPlatformBrowser(this.platformId)) {
            return !this.cookieService.get('consentDialogEnabled');
        }
    }

    setConsentDialogEnabled(value) {
        if (isPlatformBrowser(this.platformId)) {
            this.cookieService.put('consentDialogEnabled', value ? '1' : '0');
        }
    }

    getGaEnabledCookie() {
        if (isPlatformBrowser(this.platformId)) {
            return this.cookieService.get('gaEnabled');
        }
    }

    setGaEnabledCookie(value) {
        if (isPlatformBrowser(this.platformId)) {
            this.cookieService.put('gaEnabled', value ? '1' : '0');
        }
    }

    getFbqEnabledCookie() {
        if (isPlatformBrowser(this.platformId)) {
            return this.cookieService.get('fbqEnabled');
        }
    }

    setFbqEnabledCookie(value) {
        if (isPlatformBrowser(this.platformId)) {
            this.cookieService.put('fbqEnabled', value ? '1' : '0');
        }
    }
}
