import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AlertService } from './alert.service';
import { Store } from '../store';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(
        private alertService: AlertService,
        private store: Store,
    ) {
    }

    path() {
        return 'https://api.ysprod.co.uk/';
        // return 'http://localhost:3000/';
    }

    getHttpOptions() {
        const token = this.store.selectForLocal('token');
        const headers = {
            'domain': 'ys',
            'api-key': '6bf9284a-ebf9-4afa-a185-4d2b9bb29e85',
        }
        if (environment.emulateSslForLocalDev) {
            headers['x-forwarded-proto'] = 'https';
        }
        return {
            headers: new HttpHeaders(headers),
            withCredentials: true
        };
    }

    handleError(error: HttpErrorResponse) {
        // this.alertService.error(['There was a problem']);
        if (error.error) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        return throwError(
            'Something bad happened; please try again later.'
        );
    }

}
