import {Directive, Input} from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: `[gaEvent][gaCategory], [gaCategory]`,
    exportAs: 'gaCategory'
})
export class GaEventCategoryDirective {

    constructor() {
    }

    @Input() gaCategory: string;

}
